/*
* Componente relativo alla modale di conferma
*/

import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CourseEdition, CourseModuleUtil } from '../../../shared/models/course.model';
import * as langModel from '../../../shared/models/lang.model';
import { parseBoolean, ItemAttributeTypes, sortByRules, ItemTakerEnrollAttributeTypes } from 'src/cm2-commonclasses';
import { ItemUtil } from 'src/app/shared/models/item.model';
import { LangsService } from '../../services/langs.service';
import moment = require('moment');
import { UrlService } from 'src/app/shared/services/url.service';
import { ToastrService } from 'ngx-toastr';
import { TakerService } from 'src/app/takers/services/taker.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'confirm-clone-initiative-modal',
    templateUrl: './confirm-clone-initiative-modal.component.html'
})
export class ConfirmCloneInitiativeModalComponent implements OnInit {

    @ViewChild('confirmCloneInitiative') confirmCloneInitiative: any;


    constructor(public ngxSmartModalService: NgxSmartModalService,
        private langsService: LangsService,
        private urlService: UrlService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private takerService: TakerService,
        private authService: AuthService) {

    }

    ngOnInit() {
    }

    get hasToAcceptPrivacy(): any {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.hasToAcceptPrivacy;
        }
        return null;
    }

    get maxUsableTakes(): any {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.maxUsableTakes;
        }
        return null;
    }

    get enableUsableTakesHint(): any {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.enableUsableTakesHint;
        }
        return null;
    }

    get courseDayIndex(): boolean {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.courseDayIndex;
        }
        return null;
    }

    get edition(): CourseEdition {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.courseEdition;
        }
        return null;
    }

    get onlineLink(): string {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.onlineLink;
        }
        return null;
    }

    get onlineLinkTitle(): string {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.onlineLinkTitle;
        }
        return null;
    }

    get otherEditionData() {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.otherEditionData;
        }
        return null;
    }

    get editionCustomTitle() {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.customTitle;
        }
        return null;
    }

    get editionCustomSubTitle() {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.customSubTitle;
        }
        return null;
    }

    // Apre la pagina della privacy del course
    openCoursePrivacy() {
        CourseModuleUtil.openPrivacy('takers/coursePrivacy/' + this.confirmCloneInitiative.getData().modalData.initiativeId, this.urlService);
    }

    hasPdfPrivacy(): any {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.hasPdfPrivacy;
        }
        return null;
    }

    openPrivacyPdf() {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData().modalData) {
            this.takerService.getDeliberationUploadUrl(this.confirmCloneInitiative.getData().modalData.user.userId, this.confirmCloneInitiative.getData().modalData.privacyFileData.uploadId)
                .subscribe((data) => {
                    if (data && data.error) {
                        this.toastr.error(this.translate.instant('errors.' + data.error));
                    } else {
                        let getTokenFromKeyPromise = this.getTokenFromSsortkqp();
                        getTokenFromKeyPromise.then((ssorqtp: string) => {
                            let downloadUrl = this.urlService.getExternalResourceName(data.response, this.confirmCloneInitiative.getData().modalData.privacyFileData.fileName);
                            setTimeout(() => {
                                window.open(downloadUrl, '_blank')
                            }, 200)
                        })
                    }
                }, (err) => {
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                })
        }
    }

    // Recupera token dalla chiave dell'url
    getTokenFromSsortkqp() {
        return new Promise((resolve, reject) => {
            this.authService.crateRetrieveTokenAfterLoginWithUserId(this.confirmCloneInitiative.getData().modalData.user.userId, this.confirmCloneInitiative.getData().modalData.user.email).subscribe((senecaResponse) => {
                if (senecaResponse.error) {
                    reject();
                } else {
                    if (senecaResponse && senecaResponse.response) {
                        resolve(senecaResponse.response);
                    } else {
                        resolve(null);
                    }
                }
            },
                (err) => {
                    reject();
                })
        }
        )
    }

    getCurrentLang() {
        if (this.confirmCloneInitiative && this.confirmCloneInitiative.getData()) {
            return this.confirmCloneInitiative.getData().modalData.currentLang;
        }
        return null;
    }

    changeSyllabusOp(type: string) {
        this.confirmCloneInitiative.getData().modalData.syllabusOperation = type;
    }

    emitConfirmation(data) {
        if (data.confirmAction) {
            data.confirmAction(data);
        }
    }
}