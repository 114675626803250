<!-- Modale di conferma -->
<ngx-smart-modal #confirmCloneInitiative identifier="confirmCloneInitiative"
    customClass="nsm-dialog-animation-fade full-width nsm-centered confirm-modal">
    <div *ngIf="confirmCloneInitiative.hasData()">
        <!-- Titolo -->
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="modal-title-container">
            <p class="modal-title" translate="cloneInitiative.TITLE_MODAL"></p>
        </div>

        <!-- Dettaglio online -->
        <div fxFlexFill fxLayout="column" fxLayoutAlign="start start"
            class="modal-body-container theme-primary clone-initiative">
            <h2 translate="cloneInitiative.TITLE"></h2>
            <br>
            <h5 [innerHTML]="'cloneInitiative.DESCR' | translate"></h5>

            <div class="syllabus-choice-modal">
                <!-- Mantieni syllabus -->
                <div class="syllabus-option" (click)="changeSyllabusOp('CLONE')">
                    <input type="radio" #cloneSyllabusOp
                        [checked]="confirmCloneInitiative.getData().modalData.syllabusOperation == 'CLONE'" />
                    <div class="radio-visual"
                        [ngClass]="{'checked': confirmCloneInitiative.getData().modalData.syllabusOperation == 'CLONE' }">
                        <div class="radio-dot"></div>
                    </div>
                    <label for="cloneSyllabusOp" translate="cloneInitiative.CLONE"></label>
                </div>
                <!-- Clona syllabus -->
                <div class="syllabus-option" (click)="changeSyllabusOp('MANTAIN')">
                    <input type="radio" #mantainSyllabusOp
                        [checked]="confirmCloneInitiative.getData().modalData.syllabusOperation == 'MANTAIN'" />
                    <div class="radio-visual"
                        [ngClass]="{'checked': confirmCloneInitiative.getData().modalData.syllabusOperation == 'MANTAIN' }">
                        <div class="radio-dot"></div>
                    </div>
                    <label for="mantainSyllabusOp" translate="cloneInitiative.MANTAIN"></label>
                </div>
            </div>

            <!-- Titolo nuova iniziativa clonata -->
            <div class="custom-select generali-input small" fxFlexFill>
                <div class="title-input">
                    <h5 translate="cloneInitiative.CLONED_INITAITIVE_NAME"></h5>
                </div>
                <input class="full-width" type="text" [(ngModel)]="confirmCloneInitiative.getData().modalData.title"
                    name="initiativeName" placeholder="{{ 'awWizard.generalInfo.TYPE_INITIATIVE_NAME' | translate }}"
                    attr.aria-label="{{ 'cloneInitiative.CLONED_INITAITIVE_NAME' | translate }}" required>
            </div>
        </div>

        <!-- Azioni-->
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center" class="modal-actions-container">
            <!-- Annulla -->
            <button
                (click)="confirmCloneInitiative.close(); confirmCloneInitiative.getData().dismissAction(confirmCloneInitiative.getData().componentRef)"
                class="btn-secondary-1 " ngClass.xs="small" ngClass.gt-xs="large"
                type="button">{{confirmCloneInitiative.getData().cancelBtnLabel}}</button>
            <!-- Conferma -->
            <button *ngIf="confirmCloneInitiative.getData().confirmBtnLabel"
                (click)="confirmCloneInitiative.close(); emitConfirmation(confirmCloneInitiative.getData());"
                [disabled]="!confirmCloneInitiative.getData().modalData.syllabusOperation || !confirmCloneInitiative.getData().modalData.title?.trim()?.length"
                class="btn-primary-1 " ngClass.xs="small" ngClass.gt-xs="large"
                type="button">{{confirmCloneInitiative.getData().confirmBtnLabel}}</button>
        </div>
    </div>
</ngx-smart-modal>