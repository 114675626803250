<!-- Sidenav di sinistra -->
<ng-sidebar-container
  [ngClass]="{ 'gray-background-sidebar-content': isThisCurrentPage('roles') || isThisCurrentPage('roleDetail') || isThisCurrentPage('societyDetail') || isThisCurrentPage('userDetail') }">
  <!-- Sidebar di sinistra contente il menu principale -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isMainMenuSidebarOpened" mode="over" keyClose="true"
    position="left" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="main-menu-sidebar" (onCloseStart)="onMainMenuSidenavClose()"
    (onOpenStart)="onProfileMenuSidenavClose()" role="menu">
    <p translate="mainMenuSidenav.MAIN_MENU" class="title">
    </p>
    <hr>
    <!--Aggiunte voci menu sinistra per fundingSupplier-->
    <div class="item-list"
      *ngIf="!isFundingSupplier && !isSupplier && ((isManager && isAdmin) || (isPerformance && isAdmin) || isAdmin)"
      (click)="redirectService.goToHome()" role="menuitem">
      <p translate="routing.HOME">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="isFundingSupplier" class="item-list" (click)="redirectService.goToHomefundingSupplier()"
      role="menuitem">
      <p translate="routing.HOME">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="isSupplier && !isFundingSupplier" (click)="redirectService.goToHomeSupplier()">
      <p translate="routing.HOME">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="isFundingSupplier" class="item-list" (click)="redirectService.goToOffers()">
      <p translate="routing.OFFERS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="isFundingSupplier" class="item-list" (click)="redirectService.goToInvoices()">
      <p translate="routing.INVOICES">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="isFundingSupplier" class="item-list" (click)="redirectService.goToMaterials()">
      <p translate="routing.MATERIALS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="isFundingSupplier" class="item-list" (click)="redirectService.goToCommunications()">
      <p translate="routing.COMMUNICATIONS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="false" class="item-list" (click)="redirectService.goToTemplates()" role="menuitem">
      <p translate="routing.TEMPLATES">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="isManager" (click)="redirectService.goToMeetings()" role="menuitem">
      <p translate="routing.MEETINGS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="isPerformance" (click)="redirectService.goToPerformanceManagement()" role="menuitem">
      <p translate="routing.PERFORMANCE">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="!isSupplier && !isFundingSupplier  && ((isManager && isAdmin) || (isPerformance && isAdmin) || isAdmin)"
      class="item-list" (click)="redirectService.goToInitiatives()" role="menuitem">
      <p translate="routing.INITIATIVES">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <!-- Gestione fornitori -->
    <div *ngIf="canManageSuppliers()" class="item-list" (click)="redirectService.goToSuppliers()" role="menuitem">
      <p translate="routing.SUPPLIERS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="!isSupplier && !isFundingSupplier  && ((isManager && isAdmin) || (isPerformance && isAdmin) || isAdmin)"
      class="item-list" (click)="redirectService.goToLocations()" role="menuitem">
      <p translate="routing.LOCATIONS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="!isFundingSupplier && !isSupplier && isAdmin" class="item-list" (click)="redirectService.goToWishlist()"
      role="menuitem">
      <p translate="generic.WISHLIST">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="canUserSeeLinguisticManagement()" (click)="redirectService.goToLinguisticManagement()"
      role="menuitem">
      <p translate="routing.LINGUISTIC_MANAGEMENT">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="canAccessWomenInsuranceNetwork()"
      (click)="redirectService.goToWomenInsuranceNetwork()" role="menuitem">
      <p translate="routing.WOMEN_INSURANCE">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list" *ngIf="canAccessSpecialProjects()" (click)="redirectService.goToSpecialProjects()"
      role="menuitem">
      <p translate="routing.SPECIAL_PROJECTS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="canUserSeeDashboard() || isSupplierViewMandatory()" class="item-list"
      (click)="redirectService.goToDashboardRoles()" role="menuitem">
      <p translate="routing.DASHBOARD">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="canUserSeeDashboard()" class="item-list" (click)="redirectService.goToEditGroups()" role="menuitem">
      <p translate="routing.ROLES">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="canUserSeeDashboard()" class="item-list" (click)="redirectService.goToUpdateRoles()" role="menuitem">
      <p class="margin-right10" translate="routing.MANAGE_ROLES">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="canUserSeeDashboard() && !isUserInvestments()" class="item-list"
      (click)="redirectService.goToListGroups()" role="menuitem">
      <p translate="routing.EDIT_GROUPS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="false" class="item-list" (click)="redirectService.goToProfile()" role="menuitem">
      <p translate="routing.PROFILE">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div class="item-list"
      *ngIf="!isFundingSupplier && !isSupplier && ((!isManager && isAdmin) || (!isPerformance && isAdmin) || isAdmin)"
      (click)="redirectService.goToReportTag()" role="menuitem">
      <p translate="routing.GROUPED_REPORT_TAG">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>

    <div *ngIf="isNudgingAdmin" class="item-list" (click)="redirectService.goToNudgingReport()" role="menuitem">
      <p translate="routing.NUDGING_REPORT">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>


    <!-- Notifiche LearningUP -->
    <div class="item-list" *ngIf="!isFundingSupplier && !isSupplier && isAdmin"
      (click)="redirectService.goGoLearningUpNotifications()" role="menuitem">
      <p translate="routing.LEARNING_UP_NOTIFICATIONS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <!-- Import Avvisi Finanziamento -->
    <div class="item-list" *ngIf="isManagerFuning && canAccessImportFinancingNotices"
      (click)="redirectService.goToImportFinancingNotices()" role="menuitem">
      <p translate="routing.IMPORT_FINANCING_NOTICE">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <!-- <div class="item-list"
      *ngIf="!isFundingSupplier && !isSupplier && ((!isManager && isAdmin) || (!isPerformance && isAdmin) || isAdmin)"
      (click)="redirectService.goToListUsersAdmin()" role="menuitem">
      <p translate="routing.USERS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div> -->
  </ng-sidebar>

  <!-- Sidebar di destra contenente le impostazioni di profilo (notifiche, impostazioni, logout) -->
  <ng-sidebar *ngIf="!(isFetchingLangs$ | async)" [(opened)]="isProfileMenuSidebarOpened" mode="over" keyClose="true"
    position="right" closeOnClickBackdrop="true" showBackdrop="true" [animate]="initialAnimateEnabled" trapFocus="true"
    autoFocus="true" sidebarClass="profile-menu-sidebar" role="menu" (onCloseStart)="onProfileMenuSidenavClose()"
    (onOpenStart)="onMainMenuSidenavClose()">
    <div *ngIf="loggedUser && loggedUser.user" role="menuitem" class="margin-top16">
      <img class="img-avatar-coursemanager-home" [src]="avatar"
        alt="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}"
        title="{{'accessibilityAttributes.AVATAR_IMAGE_USER' | translate}}">
      <div class="wrapper-name-role truncate">
        <p class="user-name margin0">{{ loggedUser.user.forename }} {{ loggedUser.user.surname }}</p>
        <p *ngIf="loggedUser.user.jobFamilyPrimaria" class="user-role margin0">{{
          loggedUser.user.jobFamilyPrimaria }}
        </p>
      </div>
    </div>
    <hr>
    <div *ngIf="false" class="item-list" role="menuitem" (click)="redirectService.goToHome()" role="menuitem">
      <span class="badge-notify-coursemanager-sidenav margin0">{{notificationList.length}}</span>
      <p translate="header.NOTIFICATIONS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <div *ngIf="false" class="item-list" role="menuitem" (click)="redirectService.goToProfile()" role="menuitem">
      <svg-icon src="assets/img/gear-impostazioni.svg"></svg-icon>
      <p translate="header.SETTINGS">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
    <!-- Logout -->
    <div class="item-list" role="menuitem" (click)="onLogout()">
      <p translate="header.LOGOUT">
      </p>
      <svg-icon src="assets/img/gear-freccia-verso-destra.svg"></svg-icon>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>

    <app-header *ngIf="isAuthenticated && !(isFetchingLangs$ | async) 
            && !isThisCurrentPage('meetingSubscription') && !isThisCurrentPage('courseSubscription')
            && !isThisCurrentPage('userCourses')
            && !isThisCurrentPage('europeAssistanceUsers')
            && !isThisCurrentPage('myWelfareEnel')
            && !isThisCurrentPage('availableSubscriptions')
            && !isThisCurrentPage('teacherCourses')
            && !isThisCurrentPage('smartEnergyTakers')
            && !isThisCurrentPage('supportCourses') 
            && !isThisCurrentPage('coursePrivacy') 
            && !isThisCurrentPage('survey') 
            && !isThisCurrentPage('heritage')
            && !isThisCurrentPage('dei')" (activateAnimation)="activateAnimation()"
      [ngClass]="{'margin-bottom0': isThisCurrentPage('medexCourseSubscription')}"></app-header>
    <!-- Non mostro l'applicazione finché non si è conclusa la chiamata ai servizi per recuperare le lingue disponibili a sistema -->
    <!-- Nome della pagina-->
    <div fxLayout="row" *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader && getPageName()" fxFlexFill
      fxLayoutAlign="center center" class="bg-primary-3 sectionTitle">
      <p>{{getPageName()}}</p>
    </div>
    <router-outlet *ngIf="!(isFetchingLangs$ | async) && !showApplicationLoader">
    </router-outlet>
  </div>
</ng-sidebar-container>
<!-- Loader dell'applicazione, del caricamento delle pagine e del recupero delle lingue disponibili, o ogniqualvolta il valore "showApplicationLoader " nello Store è vero -->
<div *ngIf="(isFetchingLangs$ | async) || showApplicationLoader" class="app-loading">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <!-- Testo personalizzato -->
    <p *ngIf="true">Caricamento in corso</p>
  </div>
  <!-- Logo Generali -->
  <div *ngIf="!isThisCurrentPage('welfareEnelLogin') && !isThisCurrentPage('welfareEnelLocalLogin')" class="logo">
  </div>
  <!-- Logo Enel -->
  <div *ngIf="isThisCurrentPage('welfareEnelLogin')" class="enel-logo"></div>
  <div *ngIf="isThisCurrentPage('welfareEnelLocalLogin')" class="enel-logo"></div>
</div>
<!-- Componente della modale di conferma -->
<confirm-modal></confirm-modal>
<!-- Componente della modale di privacy dell'utenza pubblica -->
<public-users-privacy-modal></public-users-privacy-modal>
<!-- Componente della modale che suggerisce all'utente di cambiare browser -->
<switch-browser-modal></switch-browser-modal>