/*
 * Servizio che permette le chiamate rest relative alle iniziative (corsi)
*/
import * as CommonClasses from "../../../cm2-commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as InitiativeActions from '../ngrx/initiative.actions';

import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from "../../shared/models/global-application-data.model";
import { Observable, Subscription, of, timer } from "rxjs";
import { combineLatest } from "rxjs";
import { Item, User, Supplier_new, SupplierTask, Note, MailQueue, ItemAttributeTypes, ItemAttributeStatusTypes, ItemTakerEnrollStatusTypes, MapById, SupplierTaskStatusTypes, Upload, Tenant, Tag, SenecaResponse } from "../../../cm2-commonclasses";
import { ExtendedTag } from "../../shared/models/extended-tag.model";
import { map, switchMap, distinctUntilChanged, filter, catchError, timeout, takeUntil, take } from "rxjs/operators";
import { ImportPersonsAsTakersResponse } from "../../shared/models/importpersons-as-takers-response.model";
import { ExtendedItem } from "../../shared/models/extended-item.model";
import * as moment from "moment";

interface StageAnalytics {
    // Numero totale di edizioni associate allo stage
    totalEditionsCount: number;
    // Numero delle edizioni attualmente in corso associate allo stage
    inProgressEditionsCount: number;
    // Numero degli utenti (taker) che hanno confermato la partecipazione a un'edizione dello stage
    confirmedTakersCount: number;
    // Numero degli utenti (taker) che sono attualmente in stato "invitato" per un'edizione dello stage
    invitedTakersCount: number;
}

@Injectable()
export class InitiativeService {

    applicationData: GlobalApplicationData;
    fromRecord: number;
    numRecords: number;
    result$: Subscription;
    currentPage: number;
    initiatives: any[];
    initiativeType: string[];
    initiativeTextFilter: string;

    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient) {
        // Dallo Store applicativo, ricavo l'url per le chiamate rest e anche la pagina corrente in cui mi trovo nella visualizzazione delle iniziative
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        let numRecords$: Observable<number> = this.store.select(fromApp.getInitiativesNumRecords);
        let fromRecord$: Observable<number> = this.store.select(fromApp.getInitiativesFromRecord);
        let currentPage$: Observable<number> = this.store.select(fromApp.getInitiativesPage);
        let initiativeType$: Observable<string> = this.store.select(fromApp.getInitiativeFilterType);
        let initiativeTextFilter$: Observable<string> = this.store.select(fromApp.getInitiativesTextFilter);
        const combinedSelectes$ = combineLatest(globalApplicationData$, fromRecord$, numRecords$, currentPage$, initiativeType$, initiativeTextFilter$);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData, fromRecord, numRecords, currentPage, initiativeType, initiativeTextFilter]) => {
                this.applicationData = globalApplicationData;
                this.fromRecord = fromRecord;
                this.numRecords = numRecords;
                this.currentPage = currentPage;
                this.initiativeTextFilter = initiativeTextFilter;
                if (initiativeType && initiativeType.length) {
                    this.initiativeType = [initiativeType];
                } else {
                    this.initiativeType = null;
                }
            });
    }

    // Converte la pagina in una paginazione
    paginate(): { fromRecord: number, numRecords: number } {
        let fromRecord: number = 0;
        // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
        if (this.currentPage && this.numRecords) {
            fromRecord = (this.currentPage - 1) * this.numRecords;
        } else {
            fromRecord = 0;
        }

        let pagination = {
            fromRecord: fromRecord,
            numRecords: this.numRecords
        }

        // Eseguo il dispatch dell'azione che salva la paginazione
        this.store.dispatch(new InitiativeActions.SetPagination(pagination));

        return pagination;
    }

    // Recupera una lista di fornitori
    getSuppliers(fromRecord?: string | number, numRecords?: string | number, supplierName?: string, supplierType?: string[]): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('supplierName', supplierName || '');
        if (supplierType && supplierType.length) {
            supplierType.forEach(supplierType => {
                httpParams = httpParams.append('supplierType', supplierType);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Tag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-suppliers-by-any/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera una iniziative potenzialmente aggiungibili come tappa ad un percorso
    listAvailableInitiativesForPath(fromRecord?: string | number, numRecords?: string | number, title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('title', title || '');

        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Item[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-initiatives-for-path/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera il count delle iniziative potenzialmente aggiungibili come tappa ad un percorso
    countAvailableInitiativesForPath(title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');

        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Tag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-available-initiatives-for-path', {
            params: httpParams
        });
    }

    // Recupera una lista di offerte eseguite da un fornitore
    listConfirmedProposalOfCourseSupplier(fromRecord?: string | number, numRecords?: string | number, supplierId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('supplierId', supplierId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Tag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-confirmed-proposal-of-course-supplier/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera una lista di offerte eseguite da un fornitore
    getSupplierProposalMailRecipients(supplierId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('supplierId', supplierId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailQueue[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-supplier-proposal-mail-recipients/' + supplierId, {
            params: httpParams
        });
    }

    // Recupera una lista di firme
    listAvailableMailSignatures(): any {
        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailSender[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-mail-signatures-for-supplier', {
            });
        } else {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailSender[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-mail-signatures', {
            });
        }
    }

    // Recupera una lista di mail sender
    listAvailableMailSenders(): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', "true");
        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailSender[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-mail-senders-for-supplier', {
                params: httpParams
            });
        } else {
            return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailSender[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-mail-senders', {
                params: httpParams
            });
        }

    }

    // Recupera le ultime iniziative disponibili
    getLastLastInitiatives(fromRecord?: string, numRecords?: string, itemTypes?: string, title?: string, suspended?: boolean, archived?: boolean, pmSelected?: string, startDate?, endDate?
        , funding?, notice?, moduleName?, editionNumber?,
    ): any {
        // let pagination = this.paginate();
        // let from = fromRecord || pagination.fromRecord.toString();
        // let to = numRecords || pagination.numRecords.toString();
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord);
        httpParams = httpParams.append('numRecords', numRecords);
        if (startDate) {
            httpParams = httpParams.append('startDate', startDate.toString());
        }
        if (endDate) {
            httpParams = httpParams.append('endDate', endDate.toString());
        }
        httpParams = httpParams.append('title', title || this.initiativeTextFilter || '');
        httpParams = httpParams.append('suspended', `${suspended}`);
        httpParams = httpParams.append('archived', `${archived}`);
        httpParams = httpParams.append('inThePast', `${archived}`);
        // if ((itemTypes && itemTypes.length)) {
        //     let types = itemTypes;
        //     types.forEach(itemTypes => {
        itemTypes != '' ? httpParams = httpParams.append('itemTypes', itemTypes) : httpParams = httpParams;
        pmSelected ? httpParams = httpParams.append('referencePm', pmSelected) : httpParams = httpParams;
        //     });
        // }

        if (funding) {
            httpParams = httpParams.append('funding', funding);
        }
        if (notice) {
            httpParams = httpParams.append('notice', notice);
        }
        if (moduleName) {
            httpParams = httpParams.append('moduleName', moduleName);
        }
        if (editionNumber) {
            httpParams = httpParams.append('editionNumber', editionNumber);
        }

        return this.http.get<CommonClasses.SenecaResponse<ExtendedTag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-latest-opened-initiatives/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera le ultime iniziative
    getLatestOpenedSuspendedInitiatives(fromRecord?: string, numRecords?: string, itemTypes?: string[], title?: string): any {
        let pagination = this.paginate();
        let from = fromRecord || pagination.fromRecord.toString();
        let to = numRecords || pagination.numRecords.toString();
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from);
        httpParams = httpParams.append('numRecords', to);
        httpParams = httpParams.append('title', title || this.initiativeTextFilter || '');
        if ((itemTypes && itemTypes.length) || (this.initiativeType && this.initiativeType.length)) {
            let types = itemTypes || this.initiativeType;
            types.forEach(itemTypes => {
                httpParams = httpParams.append('itemTypes', itemTypes);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-latest-opened-suspended-initiatives/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Recupera le ultime iniziative sospese
    getLatestOpenedInProgressInitiatives(fromRecord?: string, numRecords?: string, itemTypes?: string[], title?: string): any {
        let pagination = this.paginate();
        let from = fromRecord || pagination.fromRecord.toString();
        let to = numRecords || pagination.numRecords.toString();
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from);
        httpParams = httpParams.append('numRecords', to);
        httpParams = httpParams.append('title', title || this.initiativeTextFilter || '');
        if ((itemTypes && itemTypes.length) || (this.initiativeType && this.initiativeType.length)) {
            let types = itemTypes || this.initiativeType;
            types.forEach(itemTypes => {
                httpParams = httpParams.append('itemTypes', itemTypes);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-latest-opened-in-progress-initiatives/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Verifica se esiste già un Supplier agganciato all'utente 
    getSupplierFromFacultyMember(facultyMemberUserId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('facultyMemberUserId', facultyMemberUserId);
        return this.http.get<CommonClasses.SenecaResponse<Supplier_new>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-supplier-from-faculty-member/' + facultyMemberUserId, {
            params: httpParams
        });
    }

    // Recupera il contatore delle offerte create da un determinato fornitore
    countConfirmedProposalOfCourseSupplier(supplierId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('supplierId', supplierId);
        return this.http.get<CommonClasses.SenecaResponse<ExtendedTag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-confirmed-proposal-of-course-supplier', {
            params: httpParams
        });
    }

    // Recupera l'ultima email di invito inviata ad una specifica persona
    getSentMailFromReference(userId: string, mailType: string, referenceType: string, referenceId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('mailType', mailType || '');
        httpParams = httpParams.append('referenceType', referenceType || '');
        httpParams = httpParams.append('referenceId', referenceId || '');
        return this.http.get<CommonClasses.SenecaResponse<MailQueue>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-sent-mail-from-reference', {
            params: httpParams
        });
    }

    // Recupera l'ultima email di invito inviata ad una specifica persona
    listTakerStatusesWithCount(stageItemId: string, editionItemId: string | string[]): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = !!stageItemId ? httpParams.append('stageItemId', stageItemId) : httpParams;
        httpParams = httpParams.append('editionItemId', editionItemId && typeof editionItemId == 'string' ? editionItemId : JSON.stringify(editionItemId));
        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-taker-statuses-with-count-for-supplier', {
                stageItemId: stageItemId,
                editionItemId: editionItemId
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-taker-statuses-with-count', {
                stageItemId: stageItemId,
                editionItemId: editionItemId
            });
        }
    }

    // Servizio che recupera i link della giornata
    getWebinarDayLink(itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-webinar-day-link', {
            params: httpParams
        });
    }

    getWebinarDaysLinks(itemIds: string | string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemIds', itemIds && typeof itemIds == 'string' ? itemIds : JSON.stringify(itemIds));
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-webinar-days-links', {
            params: httpParams
        });
    }

    // Servizio che verifica la disponibilità di un'aula in un determinato periodo
    listWebinarRoomsInSameConflictTime(sambaLiveAccountId: string, startTime: string, endTime: string, dayIdToIgnore?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('sambaLiveAccountId', sambaLiveAccountId);
        httpParams = httpParams.append('startTime', startTime);
        httpParams = httpParams.append('endTime', endTime);
        httpParams = dayIdToIgnore ? httpParams.append('dayIdToIgnore', dayIdToIgnore) : httpParams;
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-webinar-rooms-in-same-conflict-time', {
            params: httpParams
        });
    }

    // Recupera tutti gli id delle persone in un determinato stato
    getTakerIdsForStatus(stageItemId: string, editionItemId: string | string[], takerStatus?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = !!stageItemId ? httpParams.append('stageItemId', stageItemId) : httpParams;
        httpParams = httpParams.append('editionItemId', editionItemId && typeof editionItemId == 'string' ? editionItemId : JSON.stringify(editionItemId));
        httpParams = takerStatus && takerStatus !== 'ALL' ? httpParams.append('takerStatus', takerStatus) : httpParams;
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-takerIds-for-status', {
            stageItemId: stageItemId,
            editionItemId: editionItemId,
            takerStatus: takerStatus && takerStatus !== 'ALL' ? takerStatus : null
        });
    }

    // Recupera il counter totale persone all'interno del proprio Tenant
    countPersonsInTenant(searchedText?: string, includeMe?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText);
        }
        if (includeMe) {
            httpParams = httpParams.append('includeMe', includeMe.toString());
        }
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Lang[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-persons-in-tenant', {
            params: httpParams
        });
    }

    // Recupera l'ultima proposta richiesta ad un fornitore
    getLastProposalRequestMail(supplierTaskId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('supplierTaskId', supplierTaskId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailQueue>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-last-proposal-request-mail/' + supplierTaskId, {
            params: httpParams
        });
    }

    // Recupera l'ultima proposta richiesta ad un fornitore
    reenqueueProposalRequestMail(mailQueueId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('mailQueueId', mailQueueId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.MailQueue>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/reenqueue-proposal-request-mail/' + mailQueueId, {
            params: httpParams
        });
    }

    // Recupera una lista di persone all'interno del proprio Tenant
    getPersonsInTenant(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean): any {
        let from = fromRecord && fromRecord.toString();
        let to = numRecords && numRecords.toString();
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from);
        httpParams = httpParams.append('numRecords', to);
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText);
        }
        if (includeMe) {
            httpParams = httpParams.append('includeMe', includeMe.toString());
        }
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-persons-in-tenant/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Recupera una lista di persone all'interno del proprio Tenant
    listSupplierTasksBySupplierId(params: {
        supplierIds: string[],
        referenceId?: string,
        supplierTaskType?: string[],
        allData?: boolean,
        fromRecord?: string,
        numRecords?: string,
        withAttributesLoaded?: boolean,
        withSupplierLoaded?: boolean,
        withStatusesLoaded?: boolean,
        withClosedStatusesLoaded?: boolean,
        supplierTaskId?: string,
        statusType?: string | string[];
        searchClosedStatesToo: boolean;
    }): any {
        let from = params && params.fromRecord;
        let to = params && params.numRecords;
        let referenceId = params && params.referenceId;
        let supplierIds = params && params.supplierIds;
        let supplierTaskId = params && params.supplierTaskId;
        let supplierTaskType = params && params.supplierTaskType;
        let withSupplierLoaded = params && params.withSupplierLoaded;
        let withStatusesLoaded = params && params.withStatusesLoaded;
        let withClosedStatusesLoaded = params && params.withClosedStatusesLoaded;
        let allData = params && params.allData;
        let withAttributesLoaded = params && params.withAttributesLoaded;
        let statusTypes = CommonClasses.parseArray(params && params.statusType);
        let searchClosedStatesToo = params && params.searchClosedStatesToo;

        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('referenceId', referenceId || '');
        httpParams = httpParams.append('supplierTaskId', supplierTaskId || '');
        httpParams = withSupplierLoaded && httpParams.append('withSupplierLoaded', `${withSupplierLoaded}`) || httpParams;
        httpParams = withStatusesLoaded && httpParams.append('withStatusesLoaded', `${withStatusesLoaded}`) || httpParams;
        httpParams = withClosedStatusesLoaded && httpParams.append('withClosedStatusesLoaded', `${withClosedStatusesLoaded}`) || httpParams;
        httpParams = allData && httpParams.append('allData', `${allData}`) || httpParams;
        httpParams = withAttributesLoaded && httpParams.append('withAttributesLoaded', `${withAttributesLoaded}`) || httpParams;
        httpParams = searchClosedStatesToo && httpParams.append('searchClosedStatesToo', `${searchClosedStatesToo}`) || httpParams;
        if (Array.isArray(supplierIds)) {
            if (supplierIds && supplierIds.length) {
                supplierIds.forEach(supplierId => {
                    httpParams = httpParams.append('supplierId', supplierId);
                });
            }
        } else {
            httpParams = httpParams.append('supplierId', supplierIds);
        }
        if (supplierTaskType && supplierTaskType.length) {
            supplierTaskType.forEach(taskType => {
                httpParams = httpParams.append('taskType', taskType);
            });
        }
        // In caso di admin se non specificato recupero i task nello stato "pending" per esso
        // statusTypes = statusTypes && statusTypes.length ? statusTypes : [SupplierTaskStatusTypes.CLIENT_EVALUATING];
        if (statusTypes && statusTypes.length) {
            statusTypes.forEach(statusType => {
                httpParams = httpParams.append('statusType', statusType);
            });
        } else { }
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-supplier-tasks-by-supplierId/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Recupera le note associate al task di un supplier
    listSupplierProposalNotes(supplierTaskId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('supplierTaskId', supplierTaskId);
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Note[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-supplier-proposal-notes/' + supplierTaskId, {
            params: httpParams
        });
    }

    // Recupera il count delle note associate al task di un supplier
    countSupplierProposalNotes(supplierTaskId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('supplierTaskId', supplierTaskId);
        return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-supplier-proposal-notes/' + supplierTaskId, {
            params: httpParams
        });
    }

    // Servizio che recupera il counter delle iniziative
    countInitiatives() {
        return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-text-template-list/');
    }

    // Servizio che crea un nuovo Syllabus
    createSyllabus(syllabus: Item, isClone?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-syllabus', <CommonClasses.CourseManagerItem.CreateSyllabus>{
            syllabus: syllabus,
            isClone: isClone
        });
    }

    // Servizio che crea un nuovo Syllabus
    confirmProposalRequest(initiativeId: string, parentSupplierTaskId: string, supplierSubTaskId?: string) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/confirm-proposal-request', {
            initiativeId: initiativeId,
            supplierTaskId: parentSupplierTaskId,
            supplierSubTaskId: supplierSubTaskId
        });
    }

    // Servizio che rifiuta l'offerta
    refuseProposalRequest(supplierTaskId: string[], notifySupplier?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/refuse-proposal-request', {
            supplierTaskId: supplierTaskId,
            notifySupplier: notifySupplier
        });
    }

    // Servizio che approva/disapprova i materiali del fornitore
    changeStatusSupplierMaterial(supplierTaskId: string, isToApprove?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/approve-disapprove-supplier-material', {
            supplierTaskId: supplierTaskId,
            isToApprove: isToApprove
        });
    }

    // Recupera un upload
    getUploadById(uploadId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-upload-by-id', {
            params: httpParams
        });
    }

    // Servizio che importa una lista di persone di un Item come taker
    importPersonsAsTakers(cidOrEmailList: string[], itemId: string, masterItemId: string, simulate: boolean, syllabusId?: string, isSupplier?: boolean, webinarUserRole?) {
        if (isSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/import-persons-as-takers-for-supplier', {
                cidOrEmailList: cidOrEmailList,
                itemId: itemId,
                masterItemId: masterItemId,
                simulate: simulate,
                syllabusId: syllabusId,
                webinarUserRole: (webinarUserRole && webinarUserRole.code) || ''
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/import-persons-as-takers', {
                cidOrEmailList: cidOrEmailList,
                itemId: itemId,
                masterItemId: masterItemId,
                simulate: simulate,
                webinarUserRole: (webinarUserRole && webinarUserRole.code) || ''
            });
        }
    }

    // Esegue la creazione di un materiale
    newMaterialCourse(uploadObj) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('fileName', uploadObj.fileName);
        formData.append('itemType', uploadObj.itemType);
        formData.append('title', uploadObj.title);
        formData.append('fileType', uploadObj.fileType);
        formData.append('objectType', uploadObj.objectType);
        formData.append('materialType', uploadObj.materialType);
        formData.append('parentId', uploadObj.parentId);
        let params = new HttpParams();
        const options = {
            params: params
        };

        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-multimedia-item-for-parent',
            formData, options
        );
    }

    // Esegue l'aggiornamento di un materiale
    updateMaterial(uploadObj) {
        let formData = new FormData();
        formData.append('file', uploadObj.file && uploadObj.file.file && uploadObj.file.file.rawFile);
        formData.append('fileName', uploadObj.fileName);
        formData.append('itemType', uploadObj.itemType);
        formData.append('title', uploadObj.title);
        formData.append('fileType', uploadObj.fileType);
        formData.append('objectType', uploadObj.objectType);
        formData.append('materialType', uploadObj.materialType);
        formData.append('parentId', uploadObj.parentId);
        formData.append('itemId', uploadObj.itemId);
        let params = new HttpParams();
        const options = {
            params: params
        };
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-multimedia-item',
            formData, options
        );
        /*  const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-multimedia-item', formData, options);
         return this.http.request(req); */
    }

    // Servizio che importa una lista di persone da un item ad un assessment
    copyTakersToItem(sourceItemId: string, destinationItemId: string, sourceTakerStatus?: string[]) {
        return this.http.post<CommonClasses.SenecaResponse<ImportPersonsAsTakersResponse>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/copy-takers-to-item', {
            sourceItemId: sourceItemId,
            destinationItemId: destinationItemId,
            sourceTakerStatus: sourceTakerStatus
        });
    }

    // Servizio che elimina la richiesta di proposta
    revokeProposalRequest(supplierTaskId: string, notifySupplier?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/revoke-proposal-request', {
            supplierTaskId: supplierTaskId,
            notifySupplier: notifySupplier
        });
    }

    // Modifica uno specifico attributo di un supplier Task
    changeAttributeOfSupplierProposal(supplierTaskId: string, attributeType: string, attributeValue?: string, attributeOrder?: number, removeAttribute?: boolean) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/change-attribute-of-supplier-proposal', {
            supplierTaskId: supplierTaskId,
            attributeType: attributeType,
            attributeValue: attributeValue,
            attributeOrder: attributeOrder,
            removeAttribute: removeAttribute
        });
    }

    // Servizio che cancella una nota
    deleteSupplierProposalNote(noteId: string) {
        return this.http.post<CommonClasses.SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-supplier-proposal-note/' + noteId, {
            noteId: noteId
        });
    }

    // Servizio che aggiunge una nuova nota ad un supplierTask
    createSupplierProposalNote(supplierTaskId: string, noteText: string) {
        return this.http.post<CommonClasses.SenecaResponse<Note>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-supplier-proposal-note/' + supplierTaskId, {
            noteText: noteText
        });
    }

    // Servizio che crea un nuovo Syllabus
    createSupplierForFacultyMember(facultyMemberUserId: string) {
        return this.http.post<CommonClasses.SenecaResponse<Supplier_new>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-supplier-for-faculty-member', {
            facultyMemberUserId: facultyMemberUserId
        });
    }

    // Servizio che aggancia l'offerta all'iniziativa
    assignProposal(initiativeId: string, noProposal?: boolean, courseModuleId?: string, proposalId?: string) {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/assign-proposal', {
            courseModuleId: courseModuleId,
            proposalId: proposalId,
            initiativeId: initiativeId,
            noProposal: noProposal
        });
    }

    // Invia la proposta ad un fornitore scelto
    requestProposalToSupplier(initiativeId: string, supplierId: string, mailSenderId: string, signatureId: string, messageSubject: string, messageBody: string, expectedCloseDate: Date, mailCC: string, mailBCC: string, courseModuleId: string) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/request-proposal-to-supplier', {
            initiativeId: initiativeId,
            supplierId: supplierId,
            mailSenderId: mailSenderId,
            signatureId: signatureId,
            messageSubject: messageSubject,
            messageBody: messageBody,
            expectedCloseDate: expectedCloseDate,
            mailCC: mailCC,
            mailBCC: mailBCC,
            courseModuleId: courseModuleId
        });
    }

    // Mette lo stato del fornitore già approvato (admin si è messo d'accordo in extrapiattaforma)
    requestAlreadyConfirmedToSupplier(stageId: string, supplierId: string) {
        return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/add-pre-approved-supplier-proposal', {
            stageId: stageId,
            supplierId: supplierId
        });
    }

    // Cambia lo stato di un taker di una edizione (con possibilità di inviare anche la mail)
    changeTakersEnrollStatus(newStatus: string, takerIds: string[], syllabusItemId: string,
        stageItemId: string, editionItemId: string | string[],
        mailSenderId: string, signatureId: string, mailCC: string, mailBCC: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean, sendEmails: boolean, isSupplier?: boolean) {
        if (isSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/change-takers-enroll-status-for-supplier', {
                newStatus: newStatus,
                takerIds: takerIds,
                stageItemId: stageItemId,
                editionItemId: editionItemId,
                syllabusItemId: syllabusItemId,
                mailSenderId: mailSenderId,
                signatureId: signatureId,
                messageBodyAddOn: messageBodyAddOn,
                mailCC: mailCC,
                mailBCC: mailBCC,
                sendEmails: sendEmails,
                textTemplateId: textTemplateId,
                testOnly: testOnly
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<SupplierTask>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/change-takers-enroll-status', {
                newStatus: newStatus,
                takerIds: takerIds,
                stageItemId: stageItemId,
                editionItemId: editionItemId,
                syllabusItemId: syllabusItemId,
                mailSenderId: mailSenderId,
                signatureId: signatureId,
                messageBodyAddOn: messageBodyAddOn,
                mailCC: mailCC,
                mailBCC: mailBCC,
                sendEmails: sendEmails,
                textTemplateId: textTemplateId,
                testOnly: testOnly
            });
        }
    }

    // Cambia lo stato di un taker in presente di una edizione da file excel
    setTakerPresentByFile(uploadObj, editionId: string, mailSenderId: string, mailCC: string, mailBCC: string,
        signatureId: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean, sendEmails: boolean) {

        let formData = new FormData();
        formData.append('file', uploadObj.file.rawFile);
        formData.append('editionId', editionId);
        formData.append('mailSenderId', mailSenderId);
        formData.append('mailCC', mailCC);
        formData.append('mailBCC', mailBCC);
        formData.append('signatureId', signatureId);
        formData.append('messageBodyAddOn', messageBodyAddOn);
        formData.append('textTemplateId', textTemplateId);
        formData.append('testOnly', testOnly ? 'true' : null);
        formData.append('sendEmails', sendEmails ? 'true' : null);
        let params = new HttpParams();
        const options = {
            params: params
        };
        const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/set-takers-presence-by-file', formData, options);
        return this.http.request(req);
    }

    // Servizio che recupera una lista di utenti dati i loro userId
    getUserByUserIds(userIds: string[]) {
        return this.http.post<CommonClasses.SenecaResponse<User[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/users-by-userIds', {
            userIds: userIds
        });
    }

    // Servizio che crea una nuova iniziativa (corso)
    createStage(stage: Item, pathItemId?: string) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-stage', {
            pathItemId: pathItemId,
            stage: stage
        });
    }

    // Servizio che crea una nuova iniziativa (percorso)
    createPath(path: Item) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-path', {
            path: path
        });
    }

    // Servizio che aggiorna una iniziativa (percorso)
    updatePath(path: Item) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-path', {
            path: path
        });
    }

    // Servizio che assegna una tappa ad un percorso
    assignStageToPath(stageItemId: string, pathItemId: string) {
        return this.http.post<CommonClasses.SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/assign-stage-to-path', {
            stageItemId: stageItemId,
            pathItemId: pathItemId
        });
    }

    // Servizio che rimuove una tappa da un percorso
    removeStageFromPath(stageItemId: string, pathItemId: string) {
        return this.http.post<CommonClasses.SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/remove-stage-from-path', {
            stageItemId: stageItemId,
            pathItemId: pathItemId
        });
    }

    // Servizio che cancella i taker
    deleteTakers(takerIds: string[]) {
        return this.http.post<CommonClasses.SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-takers', {
            takerIds: takerIds
        });
    }

    // Servizio che cancella alcuni taker da una specifica edizione
    deleteTakerEnrolls(takerEnrollIds: string[]) {
        return this.http.post<CommonClasses.SenecaResponse<ExtendedItem>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/delete-taker-enrolls', {
            takerEnrollIds: takerEnrollIds
        });
    }

    // Servizio che aggiorna una iniziativa (corso)
    updateStage(stage: Item, itemAttributeTypesToResolve?: string[], withFullChildrenAttributesLoaded?: boolean, withFullAttributesLoaded?: boolean) {
        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-stage-for-supplier', {
                stage: stage,
                withFullChildrenAttributesLoaded: withFullChildrenAttributesLoaded,
                withFullAttributesLoaded: withFullAttributesLoaded,
                itemAttributeTypesToResolve
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-stage', {
                stage: stage,
                withFullChildrenAttributesLoaded: withFullChildrenAttributesLoaded,
                withFullAttributesLoaded: withFullAttributesLoaded,
                itemAttributeTypesToResolve
            });
        }
    }

    addSurveyRandomGroupQuestionsLimit(surveyId: string, groupsQuestionLimit: any) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/add-or-update-survey-random-group-questions-limit', {
            surveyId: surveyId,
            groupsQuestionLimit: groupsQuestionLimit
        });
    }

    // Servizio che aggiorna un Syllabus
    updateSyllabus(syllabus: Item) {
        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-syllabus-for-supplier', {
                syllabus: syllabus
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-syllabus', {
                syllabus: syllabus
            });
        }
    }

    // Servizio che recupera una lista di iniziative
    getInitiatives() {
        return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-text-template-list/');
    }

    // Servizio che recupera il conteggio dei taker
    countTakers(stageItemId: string, editionItemIds: string[], groupByItemId?: boolean) {
        // Preparo i parametri per la richiesta http
        /* let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId || '');
        if (editionItemIds && editionItemIds.length) {
            editionItemIds.forEach(editionItemIds => {
                httpParams = httpParams.append('editionItemIds', editionItemIds);
            });
        }
        httpParams = groupByItemId ? httpParams.append('groupByItemId', 'true') : httpParams; */

        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
        if (sessionStorageIsSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<MapById<number>>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-takers-for-supplier', {
                stageItemId: stageItemId,
                editionItemIds: editionItemIds,
                groupByItemId: groupByItemId
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<MapById<number>>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-takers', {
                stageItemId: stageItemId,
                editionItemIds: editionItemIds,
                groupByItemId: groupByItemId
            });
        }
    }

    // Servizio che recupera il conteggio dei syllabus disponibili
    countAvailableSyllabuses(title?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');
        return this.http.get<CommonClasses.SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-available-syllabuses', {
            params: httpParams
        });
    }

    // Servizio che recupera i dati sulle tappe di un percorso
    getStageAnalytics(pathItemId: string, stageItemId?: string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('pathItemId', pathItemId);
        if (stageItemId && stageItemId.length) {
            httpParams = httpParams.append('stageItemId',
                JSON.stringify(stageItemId));
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-stage-analytics', {
            params: httpParams
        });
    }

    // Load a list of Tags from their parent ids, but with a flat structure
    getFlatTagsByParentIds(tagIds?: string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (tagIds && tagIds.length) {
            tagIds.forEach(tagIds => {
                httpParams = httpParams.append('tagIds', tagIds);
            });
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-flat-tags-by-parent-ids', {
            params: httpParams
        });
    }

    // Recupera una lista di aree di interesse
    getContentAreas(itemType?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', "true");
        if (itemType) {
            httpParams = httpParams.append('itemType', itemType);
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-content-areas', {
            params: httpParams
        });
    }

    // Recupera una lista di aree professionali
    getProfessionalAreas(itemType?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', "true");
        if (itemType) {
            httpParams = httpParams.append('itemType', itemType);
        }
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-professional-fields', {
            params: httpParams
        });
    }

    listEnergyPortalArgumentPreferences() {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        return this.http.get<CommonClasses.SenecaResponse<Tag[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-energy-portal-argument-preferences', {
            params: httpParams
        });
    }

    // Recupera una lista di tenant
    getTenantsByIds(tenantIds: string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (tenantIds && tenantIds.length) {
            httpParams = httpParams.append('tenantIds', tenantIds && typeof tenantIds == 'string' ? tenantIds : JSON.stringify(tenantIds));
        }

        return this.http.get<CommonClasses.SenecaResponse<Tenant[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-tenants-by-ids', {
            params: httpParams
        });
    }

    // Recupera una lista di taker
    listTakers(stageItemId: string, editionItemIds: string[], allData?: boolean, fromRecord?: number, numRecords?: string, searchedText?: string): any {
        let from = fromRecord;
        let to = numRecords;
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('allData', allData && allData.toString());
        // httpParams = !!withAllStatusesLoaded ? httpParams.append('withAllStatusesLoaded', 'true') : httpParams;
        httpParams = httpParams.append('stageItemId', stageItemId || '');
        if (editionItemIds && editionItemIds.length) {
            editionItemIds.forEach(editionItemIds => {
                httpParams = httpParams.append('editionItemIds', editionItemIds);
            });
        }
        httpParams = httpParams.append('searchedText', searchedText || '');

        let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;

        if (sessionStorageIsSupplier) {
            return this.http.post<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-takers-for-supplier/' + from + '/' + to, {
                stageItemId: stageItemId,
                editionItemIds: editionItemIds,
                allData: allData,
                fromRecord: fromRecord,
                numRecords: numRecords,
                searchedText: searchedText
            });
        } else {
            return this.http.post<CommonClasses.SenecaResponse<CommonClasses.TextTemplate[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-takers/' + from + '/' + to, {
                stageItemId: stageItemId,
                editionItemIds: editionItemIds,
                allData: allData,
                fromRecord: fromRecord,
                numRecords: numRecords,
                searchedText: searchedText
            });
        }
    }

    // Recupera una lista di syllabus disponibili per l'aggiunta
    listAvailableSyllabuses(title?: string, allData?: boolean, fromRecord?: number, numRecords?: number): any {
        let from = fromRecord;
        let to = numRecords;
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('allData', allData && allData.toString());
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('withFullAttributesLoaded', 'true');
        httpParams = httpParams.append('itemAttributeTypesToResolve',
            JSON.stringify([
                'TITLE', 'SUBTITLE', ItemAttributeTypes.ARGUMENTS,
                ItemAttributeTypes.AIMS, ItemAttributeTypes.AGENDA,
                ItemAttributeTypes.DURATION, ItemAttributeTypes.PREREQS,
                ItemAttributeTypes.APPROACH, ItemAttributeTypes.PARTNER,
                ItemAttributeTypes.CLUSTERS,
                ItemAttributeTypes.TECH_COMPETENCES, ItemAttributeTypes.SOFT_COMPETENCES,
                ItemAttributeTypes.WAVE]));
        return this.http.get<CommonClasses.SenecaResponse<CommonClasses.Item[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-available-syllabuses', {
            params: httpParams
        });
    }

    // Reinvia la mail di invito
    reenqueueInviteMail(mailQueueId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('mailQueueId', mailQueueId);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/reenqueue-invite-mail/' + mailQueueId, {
            params: httpParams
        });
    }

    // Recupera il count dei manager
    countManagers(editionItemId: string, managerType: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('editionItemId', editionItemId);
        httpParams = httpParams.append('managerType', managerType);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-managers', {
            params: httpParams
        });
    }

    // Recupera una lista di manager
    listManagers(editionItemId: string, managerType: string, mailType: string, fromRecord?: string | number, numRecords?: string | number): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('mailType', mailType);
        httpParams = httpParams.append('editionItemId', editionItemId);
        httpParams = httpParams.append('managerType', managerType);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-managers/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera una lista di id dei taker non ancora invitati
    listNotInvitedTakers(stageItemId: string, editionItemIds: string[], managerType?: string, mailType?: string): any {
        if (managerType && mailType) {
            return this.listNotNotifiedManagers(stageItemId, editionItemIds, managerType, mailType);
        } else {
            // Preparo i parametri per la richiesta http
            let httpParams = new HttpParams();
            httpParams = httpParams.append('stageItemId', stageItemId || '');
            if (editionItemIds && editionItemIds.length) {
                editionItemIds.forEach(editionItemIds => {
                    httpParams = httpParams.append('editionItemIds', editionItemIds);
                });
            }
            return this.http.post<CommonClasses.SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-not-invited-takers', {
                stageItemId: stageItemId,
                editionItemIds: editionItemIds,
                managerType: managerType,
                mailType: mailType
            });
        }
    }

    // Recupera una lista di id dei manager/hrbp non ancora notificati
    listNotNotifiedManagers(stageItemId: string, editionItemIds, managerType?: string, mailType?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId || '');
        httpParams = httpParams.append('managerType', managerType || '');
        httpParams = httpParams.append('mailType', mailType || '');
        httpParams = httpParams.append('editionItemId', editionItemIds);
        return this.http.get<CommonClasses.SenecaResponse<string[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-not-notified-managers', {
            params: httpParams
        });
    }

    // Servizio che salve una iniziativa
    saveInitiative(initiative: any) {
        return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-text-template-list/');
    }

    // Servizio che crea una nuova edizione
    createCourseModule(courseModule: Item) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-course-module', {
            courseModule: courseModule
        });
    }

    // Servizio che manda una mail con l'export contente gli utenti
    exportUsersToTrainInCurrentYearForSupplier(syllabusId: string, stageId?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('syllabusId', syllabusId || '');
        httpParams = httpParams.append('stageId', stageId || '');
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/export-users-to-train-in-current-year-for-supplier', {
            params: httpParams
        });
    }

    // Servizio che invia una mail di invito
    inviteTakers(syllabusItemId: string, stageItemId: string, takerIds: string[], mailSenderId: string, mailCC: string, mailBCC: string, signatureId: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean, editionItemId: string, managerIds: string[], managerType: string, isNotifying: boolean, isSendingValidationRequests: boolean) {
        if (managerType && (isSendingValidationRequests || isNotifying)) {
            if (isNotifying) {
                return this.notifyManagers(syllabusItemId, stageItemId, mailSenderId, mailCC, mailBCC, signatureId, messageBodyAddOn, textTemplateId, testOnly, editionItemId, managerIds, managerType);
            }
            if (isSendingValidationRequests) {
                return this.askForManagersValidation(syllabusItemId, stageItemId, mailSenderId, mailCC, mailBCC, signatureId, messageBodyAddOn, textTemplateId, testOnly, editionItemId, managerIds, managerType);
            }
        } else {
            let sessionStorageIsSupplier = sessionStorage.getItem('isSupplier') === "true" ? true : false;
            if (sessionStorageIsSupplier) {
                return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/invite-takers-for-supplier', {
                    syllabusItemId: syllabusItemId,
                    stageItemId: stageItemId,
                    takerIds: takerIds,
                    mailSenderId: mailSenderId,
                    mailCC: mailCC,
                    mailBCC: mailBCC,
                    signatureId: signatureId,
                    messageBodyAddOn: messageBodyAddOn,
                    textTemplateId: textTemplateId,
                    testOnly: testOnly
                });
            } else {
                return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/invite-takers', {
                    syllabusItemId: syllabusItemId,
                    stageItemId: stageItemId,
                    takerIds: takerIds,
                    mailSenderId: mailSenderId,
                    mailCC: mailCC,
                    mailBCC: mailBCC,
                    signatureId: signatureId,
                    messageBodyAddOn: messageBodyAddOn,
                    textTemplateId: textTemplateId,
                    testOnly: testOnly
                });
            }
        }
    }

    // Servizio che invia una richiestsa di validazione ai manager o hrbp
    askForManagersValidation(syllabusItemId: string, stageItemId: string, mailSenderId: string, mailCC: string, mailBCC: string, signatureId: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean, editionItemId: string, managerIds: string[], managerType: string) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/ask-for-managers-validation', {
            syllabusItemId: syllabusItemId,
            stageItemId: stageItemId,
            editionItemId: editionItemId,
            managerType: managerType,
            managerIds: managerIds,
            mailSenderId: mailSenderId,
            mailCC: mailCC,
            mailBCC: mailBCC,
            signatureId: signatureId,
            messageBodyAddOn: messageBodyAddOn,
            textTemplateId: textTemplateId,
            testOnly: testOnly
        });
    }

    // Servizio che invia una notifica ai manager o hrbp
    notifyManagers(syllabusItemId: string, stageItemId: string, mailSenderId: string, mailCC: string, mailBCC: string, signatureId: string, messageBodyAddOn: string, textTemplateId: string, testOnly: boolean, editionItemId: string, managerIds: string[], managerType: string) {
        return this.http.post<CommonClasses.SenecaResponse<Item>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/notify-managers', {
            syllabusItemId: syllabusItemId,
            stageItemId: stageItemId,
            editionItemId: editionItemId,
            managerType: managerType,
            managerIds: managerIds,
            mailSenderId: mailSenderId,
            mailCC: mailCC,
            mailBCC: mailBCC,
            signatureId: signatureId,
            messageBodyAddOn: messageBodyAddOn,
            textTemplateId: textTemplateId,
            testOnly: testOnly
        });
    }

    selectInitiative(initiativeId: string) {
        return this.store.dispatch(new InitiativeActions.SelectInitiative(initiativeId));
    }

    deleteSelectInitiative() {
        return this.store.dispatch(new InitiativeActions.DeleteSelectedInitativeData());
    }

    getSelectedInitiative() {
        return this.store.select(fromApp.getSelectedInitiativeItem).pipe(
            // emetto un valore soltanto esiste l'iniziativa selezionata è nello store
            filter((selectedInitiative) => {
                return !!selectedInitiative;
            })
        );
    }

    updateStoreInitiative(initiative: any): any {
        this.store.dispatch(new InitiativeActions.UpdateInitiative({ index: 0, updatedInitiative: initiative }));
    }

    getCurrenWizardStep() {
        return this.store.select(fromApp.getWizardStepOfSelectedInitiative);
    }

    setCurrentWizardStep(stepId: string) {
        this.store.dispatch(new InitiativeActions.SetCurrentWizardStep(stepId));
    }

    closeInitiative(stageItemId): any {
        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/close-initiative-for-admin', {
            stageItemId: stageItemId,
            markAbsentTakers: true,
            statusList: [ItemTakerEnrollStatusTypes.USER_STATUS_INVITED, ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED]
        });
    }

    listProposalSupplierForInitiative(taskId: string, supplierId: string, fromRecord: number, numRecords: number) {

        let httpParams = new HttpParams();
        httpParams = httpParams.append('parentSupplierTaskId', taskId);
        httpParams = httpParams.append('supplierId', supplierId);
        httpParams = httpParams.append('allData', 'true');

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-supplier-proposals-by-parent/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    listExistingGroupedReportTagAttributes(allData: string, fromRecord: string, numRecord: string, searchedText: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', allData);
        httpParams = httpParams.append('fromRecord', fromRecord);
        httpParams = httpParams.append('numRecords', numRecord);
        httpParams = httpParams.append('searchedText', searchedText);

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-existing-grouped-report-tag-attributes/', {
            params: httpParams
        });
    }

    listExistingRooms(allData: string, fromRecord: string, numRecord: string, searchedText: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', allData);
        httpParams = httpParams.append('fromRecord', fromRecord);
        httpParams = httpParams.append('numRecords', numRecord);
        httpParams = httpParams.append('searchedText', searchedText);

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-existing-location-room-name/', {
            params: httpParams
        });
    }

    recalcUsersAwardsByCourse(itemId: string, startDate: string, creditType: string) {
        return this.http.post<CommonClasses.SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/recalc-users-awards-by-course', {
            stageId: itemId,
            startDate: startDate,
            creditType: creditType
        });
    }

    // Servizio che recupera gli upload
    getSambaLiveRecordingsByIds(uploadIds: string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadIds', JSON.stringify(uploadIds));
        return this.http.get<CommonClasses.SenecaResponse<Upload[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-sambaLive-recording-uploads-by-ids', {
            params: httpParams
        });
    }

    listDefaultSurveyTemplate(attributeSurveyTypes: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('attributeSurveyTypes', attributeSurveyTypes);
        return this.http.get<CommonClasses.SenecaResponse<Upload[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-default-survey-template-by-survey-type', {
            params: httpParams
        });
    }

    uploadDefaultSurveyTemplate(referenceId: string, referenceType: string, surveyType: string, useCustomGroups: boolean, isSurveyNotRepeatable: boolean, attributeSurveyTypes: string) {
        return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-default-survey-template-by-survey-type',
            {
                referenceId: referenceId,
                referenceType: referenceType,
                surveyType: surveyType,
                useCustomGroups: useCustomGroups,
                isSurveyNotRepeatable: isSurveyNotRepeatable,
                attributeSurveyTypes: attributeSurveyTypes
            });
    }

    getDownloadSurveyUrl(templateType: string, ssorqtp: string) {
        return this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/download-survey-template-file?templateType=' + templateType + '&ssortkqp=' + ssorqtp;
    }

    usePresetTemplate(referenceId: string, referenceType: string, surveyType: string, useCustomGroups: boolean, isSurveyNotRepeatable: boolean, attributeTemplateClassName: string) {
        return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-preset-survey-template-template-name',
            {
                referenceId: referenceId,
                referenceType: referenceType,
                surveyType: surveyType,
                useCustomGroups: useCustomGroups,
                isSurveyNotRepeatable: isSurveyNotRepeatable,
                attributeTemplateClassName: attributeTemplateClassName
            });
    }
    uploadDeliberationFileForCourse(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('stageId', uploadObj.stageId);
        let params = new HttpParams();
        const options = {
            params: params
        };

        return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/upload-deliberation-file-for-course',
            formData, options
        );
    }

    sendManualPresenceRegisteredMail(editionItemIds: string) {
        return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/send-manual-presence-registered-mail-for-online-courses',
            {
                editionItemIds: editionItemIds
            });
    }

    // Recupero i count dei docenti
    getCountTutors(editionId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('editionId', editionId);
        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-count-invited-and-non-invited-teachers', {
            params: httpParams
        });
        /* return of(new SenecaResponse(null, {
            inserted: 5,
            invited: 3
        })); */
    }

    sendMailToTutors(editionId: string, signatureId: string, mailSenderId: string, sendToAll?: boolean) {
        return this.http.post<any>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-send-teacher-confirmation-mail',
            {
                editionId: editionId,
                signatureId: signatureId,
                mailSenderId: mailSenderId,
                sendToAll: sendToAll
            });
    }

    canExportLpCertificatesMassive(stageItemId: string): Observable<CommonClasses.SenecaResponse<boolean>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId);

        return this.http.get<CommonClasses.SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/can-export-lp-certificates-massive', {
            params: httpParams
        });
    }

    exportLpCertificatesMassive(stageItemId: string, params: {
        userIds?: string[];
        startDate?: string;
        endDate?: string;
    }): Observable<CommonClasses.SenecaResponse<string>> {
        const userIds: string[] = CommonClasses.parseArray(params.userIds);
        const startDate: string = params.startDate;
        const endDate: string = params.endDate;

        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId);

        // Eventualmente aggiungo degli userId target
        if (userIds) {
            for (let userId of userIds) {
                httpParams = httpParams.append('userId', userId);
            }
        }

        if (startDate) {
            httpParams = httpParams.append('startDate', moment(startDate).toISOString());
        }
        if (endDate) {
            httpParams = httpParams.append('endDate', moment(endDate).toISOString());
        }

        return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/export-lp-certificates-massive', {
            params: httpParams
        });
    }

    listExistingIdpGroupedTitles(allData: boolean, fromRecord?: string, numRecord?: string, searchedText?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', allData ? "true" : "false");
        if (!allData) {
            httpParams = httpParams.append('fromRecord', fromRecord);
            httpParams = httpParams.append('numRecords', numRecord);
        }
        if (searchedText) {
            httpParams = httpParams.append('searchedText', searchedText);
        }

        return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-existing-idp-grouped-titles/', {
        })
    }
}
