import { RequiredAuth, SupplierPersonJwtPayload } from "src/cm2-commonclasses";

/*
 * Modello che dinisce una classe con i dati comuni e utili per l'applicazione (tipo il context)
*/

export class GlobalApplicationData {
  // Prefisso applicativo (context) e le altre varibili del server
  public applicationContext: string;
  public isAliveAddress: string;
  public isMaintenanceAddress: string;
  public eTicketResourcePath: string;
  public orginalRequestedURL: string;
  // Indica se mostrare un alert di "sessione/token scaduta/o"
  public showSessionExpiredAlert: boolean;
  // Indica alla pagina di autorizzazione che deve effettuare un reload completo, in modo da pulire tutti gli status
  public forceFullPageRefresh: boolean;
  // Ruoli disponibili
  // Questo array di oggetti role/label sarà popolato durante la chiamata che recupera i dati dell'utente loggato
  public availableUserGroups: string[];
  // Azioni a cui un utente è abilitato
  public actions: string[];
  // Indica se deve essere forzata l'apertura della dialog delle preferenze utente
  public forceShowUserPrefDialog: boolean;
  // Tipo di device
  public deviceType: string;
  // Agent dell'utente
  public userAgent: string;

  constructor(applicationContext: string, isAliveAddress: string, isMaintenanceAddress: string, eTicketResourcePath: string, orginalRequestedURL: string, showSessionExpiredAlert: boolean, forceFullPageRefresh: boolean, availableUserGroups: string[], actions: string[], forceShowUserPrefDialog: boolean, deviceType: string, userAgent: string) {
    this.applicationContext = applicationContext;
    this.isAliveAddress = isAliveAddress;
    this.isMaintenanceAddress = isMaintenanceAddress;
    this.eTicketResourcePath = eTicketResourcePath;
    this.orginalRequestedURL = orginalRequestedURL;
    this.showSessionExpiredAlert = showSessionExpiredAlert;
    this.forceFullPageRefresh = forceFullPageRefresh;
    this.availableUserGroups = availableUserGroups;
    this.actions = actions;
    this.forceShowUserPrefDialog = forceShowUserPrefDialog;
    this.deviceType = deviceType;
    this.userAgent = userAgent;
  }
}

const genertelTenants = ["GENERTEL_AMM_GRP", "GENERTELLIFE_AMM_GRP", "GENERTEL_CC_CLAIMS_GRP", "GENERTEL_CC_BACKOFFICE_GRP", "GENERTEL_CC_SALES_GRP", "GENERTELLIFE_CC_GRP"];
const ghoTenants = ["GHO_GRP"];
const dasTenants = ["DAS_GRP"];
const alleanzaTenants = ["ALLEANZA_GRP", "ALLEANZA_RETE_GRP"];
const euassTenants = ["EUASS_GRP"];
const investments = ["INVESTMENTS_GRP"];

export function isGenertel(tenant) {
  if (tenant) {
    for (let genertelTenantsCounter = 0, genertelTenantsLength = genertelTenants.length; genertelTenantsCounter < genertelTenantsLength; genertelTenantsCounter++) {
      let currentGenertelTenant = genertelTenants[genertelTenantsCounter];
      if (tenant === currentGenertelTenant) {
        return true;
      }
    }
  }
  return false;
}

export function isGho(tenant) {
  if (tenant) {
    for (let ghoTenantsCounter = 0, ghoTenantsLength = ghoTenants.length; ghoTenantsCounter < ghoTenantsLength; ghoTenantsCounter++) {
      let currentGhoTenant = ghoTenants[ghoTenantsCounter];
      if (tenant === currentGhoTenant) {
        return true;
      }
    }
  }
  return false;
}

export function isDas(tenant) {
  if (tenant) {
    for (let tenantsCounter = 0; tenantsCounter < dasTenants.length; tenantsCounter++) {
      if (tenant === dasTenants[tenantsCounter]) {
        return true;
      }
    }
  }
  return false;
}

export function isEuass(tenant) {
  if (tenant) {
    for (let tenantsCounter = 0; tenantsCounter < euassTenants.length; tenantsCounter++) {
      if (tenant === euassTenants[tenantsCounter]) {
        return true;
      }
    }
  }
  return false;
}

export function isInvestments(tenant) {
  if (tenant) {
    for (let tenantsCounter = 0; tenantsCounter < investments.length; tenantsCounter++) {
      if (tenant === investments[tenantsCounter]) {
        return true;
      }
    }
  }
  return false;
}

export function isAlleanza(tenant) {
  if (tenant) {
    for (let tenantsCounter = 0; tenantsCounter < alleanzaTenants.length; tenantsCounter++) {
      if (tenant === alleanzaTenants[tenantsCounter]) {
        return true;
      }
    }
  }
  return false;
}

export function authControl(auths: string[]) {
  let authObject: any = {};
  if (auths && auths.filter((x: any) => {
    return x == RequiredAuth.COURSEMANAGER_MANAGE_MEETING_MANAGER;
  }).length
  ) {
    authObject.isManager = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.COURSEMANAGER_IS_ADMIN;
  }).length
  ) {
    authObject.isAdmin = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == RequiredAuth.COURSEMANAGER_MANAGE_PERFORMANCE;
  }).length
  ) {
    authObject.isPerformance = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == "COURSEMANAGER_NUDGING_ADMIN";
  }).length
  ) {
    authObject.isNudgingAdmin = true;
  }
  if (auths && auths.filter((y: any) => {
    return y == "COURSEMANAGER_MANAGE_FUNDING";
  }).length
  ) {
    authObject.isManagerFuning = true;
  }
  return authObject;
}