/*
 * Componente principale dell'applicazione
 */

import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { JwtPayload, RequiredAuth } from "../cm2-commonclasses";
import * as AuthActions from "./auth/ngrx/auth.actions";
import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";
import * as ProfileActions from "./profile/ngrx/profile.actions";
import { RedirectService } from "./shared/services/redirect.service";
import { TranslateService } from "@ngx-translate/core";
import { authControl } from './shared/models/global-application-data.model';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    showApplicationLoader: boolean;
    isFetchingLangs$: Observable<boolean>;
    isAuthenticated: boolean;
    result$: Subscription;
    routerEvents: Subscription;
    isMainMenuSidebarOpened: boolean;
    isProfileMenuSidebarOpened: boolean;
    notificationList: any[] = [];
    avatar: string;
    loggedUser: JwtPayload;
    // E' necessario settare l'animazione delle sidenav di default a false, perché altrimenti a causa di problemi css del componente appena si carica l'applicazione per qualche millisecondo esse sono aperte, per poi chiudersi subito.
    // Per evitare questo effetto non piacevole alla vista, setto l'animazione di default a false, che diventerà a true dopo la prima apertura
    initialAnimateEnabled: boolean = false;
    isSupplier: boolean;
    isFundingSupplier: boolean;
    authObject: any;
    isAdmin: boolean;
    isManagerFuning: boolean;
    isManager: boolean;
    isPerformance: boolean;
    isNudgingAdmin: boolean;

    isUsingInternetExplorer: boolean;
    canAccessImportFinancingNotices: boolean = environment.canAccessImportFinancingNotices;

    constructor(
        private store: Store<fromApp.AppState>,
        private router: Router,
        private translate: TranslateService,
        private ngxSmartModalService: NgxSmartModalService,
        private redirectService: RedirectService
    ) {
        // Se ho il token in sessione, setto l'utente come autenticato e avvio il polling per il token
        /*     let sessionStorageToken: string = sessionStorage.getItem('token');
            if (sessionStorageToken) {
              this.store.dispatch(new AuthActions.SetUserAuthenticated());
              // Devo settare subito il token (nonostante lo faccia già il StartRenewTokenPolling) perché potrebbero partire chiamate rest dei servizi della pagina che sto guardando e, in tal caso, partirebbero senza Authorization Bearer nella richiesta
              this.store.dispatch(new AuthActions.SetToken(sessionStorageToken));
              this.store.dispatch(new CoreActions.StartRenewTokenPolling());
            } */
        // Recupero le informazioni sull'utente loggato dallo Store applicativo
        let loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
        // Mostro il loader mentre sto caricando le lingue
        this.isFetchingLangs$ = this.store.select(fromApp.isFetchingLangs);
        // Mostro il loader se esplicitato nello Store
        let showApplicationLoader$ = this.store.select(
            fromApp.showApplicationLoader
        );
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra contente il menu principale è aperta
        let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(
            fromApp.isMainMenuSidebarOpened
        );
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra contente il menu utente è aperta
        let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(
            fromApp.isProfileMenuSidebarOpened
        );
        // Quando il componente è stato inizializzato, recupero la parte relativa all'autorizzazione dallo state applicativo e l'oggetto globale "globalApplicationData"
        let isAuthenticated$ = this.store.select(fromApp.isAuthenticated);
        // Collegamento alla variabile indicante se l'utente loggato è un supplier
        const isSupplier$: Observable<boolean> = this.store.select(fromApp.isSupplier);
        // Collegamento alla variabile indicante se l'utente loggato è fundingSupplier
        let isFundingSupplier$: Observable<boolean> = this.store.select(fromApp.isFundingSupplier);
        // Combito tutti gli observable
        let combinedSelectes$ = combineLatest(loggedUser$, isAuthenticated$, showApplicationLoader$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, isSupplier$, isFundingSupplier$);
        this.result$ = combinedSelectes$.subscribe(
            ([loggedUser, isAuthenticated, showApplicationLoader, isMainMenuSidebarOpened, isProfileMenuSidebarOpened, isSupplier, isFundingSupplier]) => {
                this.loggedUser = loggedUser;
                this.isAuthenticated = isAuthenticated;
                this.showApplicationLoader = showApplicationLoader;
                this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
                this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
                this.isSupplier = isSupplier;
                this.isFundingSupplier = isFundingSupplier;

                this.isUsingInternetExplorer = this.isInternetExplorerBrowser();

                // Se l'utente usa explorer, apro la modale che suggerisce di cambiare browser
                const switchBrowserModalViewed: string = sessionStorage.getItem('switchBrowserModalViewed');
                if (this.isUsingInternetExplorer && this.loggedUser && this.isAuthenticated && !switchBrowserModalViewed) {
                    this.openSwitchBrowserModal();
                }
            }
        );

        // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
        this.avatar =
            (this.loggedUser &&
                this.loggedUser.user &&
                this.loggedUser.user.userOptions &&
                this.loggedUser.user.userOptions.avatarImage) ||
            (this.loggedUser &&
                this.loggedUser.user &&
                this.loggedUser.user.chiaveSesso == "M"
                ? "assets/img/placeholder.png"
                : "assets/img/placeholder_female.png");
    }

    isInternetExplorerBrowser() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        }
        return false;
    }

    openSwitchBrowserModal() {
        const confimation = new EventEmitter<any>();

        const publicUsersPrivacyData: Object = {
            confirmation: confimation,
            componentRef: this
        };
        let publicUsersPrivacyRef = this.ngxSmartModalService.getModal('switchBrowserModal');
        publicUsersPrivacyRef.setData(publicUsersPrivacyData, true);
        confimation
            .pipe(take(1))
            .subscribe(() => {
                sessionStorage.setItem('switchBrowserModalViewed', "true");
            });
        // Apro la modale
        publicUsersPrivacyRef.open();
    }
    // Attiva l'animazione dell'apertura/chiusura delle sidenav
    activateAnimation(): void {
        if (!this.initialAnimateEnabled) {
            this.initialAnimateEnabled = true;
        }
    }

    // Verifica se l'utente loggato può accedere alla dashboard
    canUserSeeDashboard() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.COURSEMANAGER_MANAGE_MANDATORY_TRAINING
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }
    isUserInvestments() {
        if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userTenant && this.loggedUser.user.userTenant.tenant && this.loggedUser.user.userTenant.tenant === 'INVESTMENTS_GRP') {
            return true;
        }
        return false;
    }

    // Verifica se l'utente loggato può accedere alla gestione dei fornitori
    canManageSuppliers() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.COURSEMANAGER_MANAGE_SUPPLIERS
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }

    // Verifica se l'utente loggato può accedere alla dashboard coi ruoli
    isSupplierViewMandatory() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.COURSEMANAGER_SUPPLIER_VIEW_MANDATORY_TRAINING
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }

    // Verifica se l'utente loggato può accedere alla formazione linguistica
    canUserSeeLinguisticManagement() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.COURSEMANAGER_LINGUISTIC_MANAGEMENT
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }

    // Verifica se l'utente loggato può accedere alla gestione women
    canAccessWomenInsuranceNetwork() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.GTP_WOMEN_INSURANCE_NETWORK
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }

    canAccessSpecialProjects() {
        let canView: boolean = false;
        if (
            this.loggedUser &&
            this.loggedUser.auths &&
            this.loggedUser.auths.length
        ) {
            for (
                let i = 0, authsLength = this.loggedUser.auths.length;
                i < authsLength;
                i++
            ) {
                let currentAuth = this.loggedUser.auths[i];
                if (
                    currentAuth === RequiredAuth.GTP_MANAGE_SPECIAL_PROJECTS
                ) {
                    canView = true;
                    break;
                }
            }
            return canView;
        }
    }

    // Verifica in quale pagina sono
    isThisCurrentPage(page: string) {
        if (
            page &&
            this.router &&
            this.router.url &&
            this.router.url.indexOf(page) !== -1
        ) {
            return true;
        }

        return false;
    }

    // Ritorna il nome di una pagina
    getPageName(): string {
        let pageName: string = null;
        if (this.isThisCurrentPage("dashboard/roleDetail/")) {
            pageName = this.translate.instant("dashboard.roles.ROLE_DETAIL");
        } else if (this.isThisCurrentPage("dashboard/societyDetail/")) {
            pageName = this.translate.instant("dashboard.roles.SOCIETY_DETAIL");
        } else if (this.isThisCurrentPage("dashboard/userDetail/")) {
            pageName = this.translate.instant("dashboard.roles.TRAINING_HISTORY_DETAIL");
        } else if (this.isThisCurrentPage("dashboard/updateTitleRoles")) {
            pageName = this.translate.instant("dashboard.updateTitleRoles.PAGE_TITLE");
        } else if (this.isThisCurrentPage("dashboard") || this.isThisCurrentPage("groups")) {
            pageName = "Dashboard";
        } else if (this.isThisCurrentPage("performanceManagement")) {
            pageName = "Monitoraggio Performance management";
        }
        return pageName;
    }

    // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
    onMainMenuSidenavClose(): void {
        this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
    }

    // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
    onProfileMenuSidenavClose(): void {
        this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
    }

    // Quando il componente è distrutto, eseguo anche l'unsubscribe del cambio di route
    ngOnDestroy() {
        this.routerEvents.unsubscribe();
        this.result$.unsubscribe();
    }

    // Logout dall'applicazione
    onLogout() {
        // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
        this.store.dispatch(new AuthActions.Logout());
        // Redirect alla pagina di login
        this.router.navigate(["/login"]);
    }

    // Quando il componente si è inizializzato ed è pronto, devo settare i parametri applicativi come il base URL dell'applicazione. Di questo se ne occuperà il side effect una volta lanciata l'action per recuperare le lingue disponibili a sistema.
    ngOnInit() {
        // Recupero le lingue disponibili
        this.store.dispatch(new CoreActions.GetAvailableLangs());
    }
    ngAfterViewChecked() {
        this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
        this.isAdmin = this.authObject.isAdmin;
        this.isManager = this.authObject.isManager;
        this.isManagerFuning = this.authObject.isManagerFuning
        this.isPerformance = this.authObject.isPerformance;
        this.isNudgingAdmin = this.authObject.isNudgingAdmin;
    }
}
