/*
 * Servizio che permette le chiamate rest relative ai taker (invitati ad un corso)
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ItemAttributeTypes, SenecaResponse, ItemFrontEndWrapper, CourseManagerItem, EnrichedItemTaker, Survey, Item, ItemTakerEnrollAttribute } from "../../../cm2-commonclasses";
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { ExtendedItem } from "../../shared/models/extended-item.model";
import { ExtendedSurvey } from '../../shared/models/extended-survey.model';

@Injectable()
export class TakerService {
    applicationData: GlobalApplicationData;
    result$: Subscription;
    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient) {
        // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const combinedSelectes$ = combineLatest(globalApplicationData$);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData]) => {
                this.applicationData = globalApplicationData;
            });
    }

    // Torna uno Stage dato il suo Id
    getStageFromIdForPublic(userId: string, stageItemId: string, isLibraryItem?: boolean): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('stageItemId', stageItemId);
        httpParams = httpParams.append('isLibraryItem', isLibraryItem && isLibraryItem.toString() || '');
        httpParams = httpParams.append('withFullAttributesLoaded', 'true');
        httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
        httpParams = httpParams.append('itemAttributeTypesToResolve',
            JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER,
            ItemAttributeTypes.PERSON_IN_CHARGE
                /* TEMPLATES ItemAttributeTypes.INVITATION_MAIL, ItemAttributeTypes.SELF_CANCEL_MAIL, ItemAttributeTypes.PREREGISTRATION_MAIL,
                ItemAttributeTypes.CONFIRMATION_MAIL, ItemAttributeTypes.OB_PROMOTION_MAIL, ItemAttributeTypes.PRESENCE_REGISTERED_MAIL,
                ItemAttributeTypes.COURSE_REMINDER_MAIL */
            ]));

        return this.http.get<ExtendedItem>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-stage-from-id-for-public/' + stageItemId, {
            params: httpParams
        });
    }

    // Torna le applicazioni a cui l'utente è abilitato
    getUserMainApplicationForPublic(userId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);

        return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-user-main-application-for-public', {
            params: httpParams
        });
    }

    getDownloadTempFileUrl(filename: string, ssorqtp: string) {
        return this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/download-temp-file?filename=' + filename + '&ssortkqp=' + ssorqtp;
    }

    generateCertificates(editionId: string, userIds?: string[], asImage?: boolean, getFullPath?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', editionId);
        httpParams = getFullPath ? httpParams.append('getFullPath', 'true') : httpParams;
        httpParams = asImage ? httpParams.append('asImage', 'true') : httpParams;
        if (userIds && userIds.length) {
            userIds.forEach(userId => {
                httpParams = httpParams.append('userId', userId);
            })
        }
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-course-certificate-for-public', {
            params: httpParams
        });
    }

    // Torna la lista di certificati
    getEditionIdsWhereUserHasCertificateByStageId(stageId: string, userId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageId', stageId);
        httpParams = httpParams.append('userId', userId);

        return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-edition-ids-where-user-has-certificate-by-stage-id', {
            params: httpParams
        });
    }

    // Recupera un upload
    getUploadByIdForPublic(userId?: string, uploadId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-upload-by-id-for-public', {
            params: httpParams
        });
    }

    // Recupera una firma
    getMailSignatureTextByIdForPublic(userId: string, textTemplateId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('textTemplateId', textTemplateId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-mail-signature-text-by-id-for-public', {
            params: httpParams
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    getSurveys(userId: string, referenceId: string, itemId?: string, startIfNotStarted?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('itemId', itemId || '');
        httpParams = httpParams.append('startIfNotStarted', startIfNotStarted && startIfNotStarted.toString() || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-surveys/' + referenceId, {
            params: httpParams
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    getFlatSurveyBySurveyId(userId: string, surveyId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('surveyId', surveyId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-flat-survey-by-surveyId/' + surveyId, {
            params: httpParams
        });
    }

    // Recupera una lista di rilevazioni di un'edizione specifica
    getEditionAssessmentsForPublic(userId?: string, editionItemId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('editionItemId', editionItemId || '');
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-edition-assessments-for-public', {
            params: httpParams
        });
    }

    // Recupera una specifica survey
    getSurveyBySurveyId(userId: string, surveyId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('surveyId', surveyId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-survey-by-surveyId/' + surveyId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    listUserTakers(data: CourseManagerItem.ListUserTakersForPublic) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', data.userId);
        httpParams = httpParams.append('editionItemIds', JSON.stringify(data.editionItemIds));
        httpParams = !!data.stageItemId ? httpParams.append('stageItemId', data.stageItemId) : httpParams;
        httpParams = httpParams.append('allData', '' + data.allData);
        httpParams = httpParams.append('fromRecord', data.fromRecord);
        httpParams = httpParams.append('numRecords', data.numRecords);
        return this.http.post<SenecaResponse<Array<EnrichedItemTaker>>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-user-takers-for-public', {
            userId: data.userId,
            editionItemIds: data.editionItemIds,
            stageItemId: data.stageItemId,
            allData: data.allData,
            fromRecord: data.fromRecord,
            numRecords: data.numRecords
        });
    }

    // Servizio che verifica se un'edizione richiede il check sulla privacy
    isCoursePrivacyToAccept(userId: string, editionItemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('editionItemId', editionItemId);
        return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/is-course-privacy-to-accept', {
            params: httpParams
        });
    }

    // Servizio che recupera la descrizione di un attributo
    getItemAttributeDescriptionForPublic(attributeType: string, initiativeId: string, userId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('attributeType', attributeType);
        httpParams = httpParams.append('itemId', initiativeId);
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-attribute-description-for-public', {
            params: httpParams
        });
    }

    // Servizio che recupera il nome di un item (per i taker)
    getItemTitleByIdForPublic(userId: string, itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-title-by-id-for-public', {
            params: httpParams
        });
    }

    userSelfConfirm(userId: string, editionItemId: string, sendEmail?: boolean, usedTakes?: number, coursePrivacyAccepted?: boolean,
        additionalEnrollAttributes?: ItemTakerEnrollAttribute[]) {
        return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/user-self-confirm', {
            userId: userId,
            editionItemId: editionItemId,
            sendEmail: sendEmail,
            usedTakes: usedTakes,
            coursePrivacyAccepted: coursePrivacyAccepted,
            additionalEnrollAttributes: additionalEnrollAttributes
        });
    }

    updateSurvey(userId: string, survey: Survey, closeSurvey: boolean, itemId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-survey', {
            userId: userId,
            survey: survey,
            closeSurvey: closeSurvey,
            itemId: itemId
        });
    }

    userSelfCancel(userId: string, editionItemId: string, statusNote: string, sendEmail?: boolean) {
        return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/user-self-cancel', {
            userId: userId,
            editionItemId: editionItemId,
            sendEmail: sendEmail,
            statusNote: statusNote
        });
    }

    listUserMaterialsForParent(userId: string, fromRecord: number, numRecords: number, parentIds: string[], materialTypes: string[]) {
        return this.http.post<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-user-materials-for-parent', {
            userId: userId,
            fromRecord: fromRecord,
            numRecords: numRecords,
            parentId: parentIds,
            materialType: materialTypes
        });
    }

    countUserMaterialsForParent(userId: string, parentIds: string[], materialTypes: string[]) {
        return this.http.post<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-user-materials-for-parent', {
            userId: userId,
            parentId: parentIds,
            materialType: materialTypes
        });
    }

    updateStatusFromPlayer(userId: string, itemId: string, eventName: string, totalTime: number, currentTime: number) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/update-status-from-player/${itemId}/${eventName}/${totalTime}/${currentTime}`, {
            userId: userId
        });
    }

    retriveAttachmentUrl(userId: string, item: Item, attachmentId: string, adminMode: boolean, isItemOtherType: boolean) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/get-attachment-url/${attachmentId}`, {
            userId: userId,
            item: item,
            adminMode: adminMode,
            isItemOtherType: isItemOtherType
        });
    }

    countUsedTakesForPublic(userId: string, editionItemIds: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('editionItemIds', JSON.stringify(editionItemIds));

        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/count-used-takes-for-public`, {
            userId: userId,
            editionItemIds: editionItemIds
        });
    }

    getDeliberationUploadUrl(userId: string, uploadId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('uploadId', uploadId);

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-deliberation-upload-url-for-course', {
            params: httpParams
        });
    }

}