/*
* Componente che crea la tabella con le ultime iniziative
*/

import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, HostListener } from "@angular/core"
import { Item, ItemTypes, ItemAttributeTypes, ItemAttribute, ItemAttributeStatusTypes, ItemTakerEnrollStatusTypes, SenecaResponse, KeyValue } from "../../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
import { CourseModuleUtil, CourseEdition, CourseEditionUtil } from "src/app/shared/models/course.model";
import { LangsService } from '../../services/langs.service';
import moment = require("moment");
import { ItemUtil } from "src/app/shared/models/item.model";
import { take } from "rxjs/operators";
import { InitiativeService } from '../../../initiatives/services/initiative.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'last-initiatives',
    templateUrl: './last-initiatives.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastInitiativesComponent implements OnInit {
    @Input() lastInitiatives: Item[];
    dateFormat: string;
    openedMenu: boolean[];
    initiative: Item;
    closeInitiativeEnabled: boolean;
    courseEditionList: CourseEdition[];
    currentStatus: any;

    @Input() canClone: boolean = false;
    @Output() openCloneInitiativeConfirmModal = new EventEmitter<any>();

    @Output() openRemoveInitiativeConfirmModal = new EventEmitter<any>();
    @Output() goToEdit = new EventEmitter<any>();
    @Output() getLastInitiatives = new EventEmitter<any>();
    @ViewChildren('menu') menu;
    constructor(private translate: TranslateService,
        private langsService: LangsService,
        private initiativeService: InitiativeService,
        public ngxSmartModalService: NgxSmartModalService,
        private toastr: ToastrService) {

    }


    ngOnInit(): void {
        this.initiative = null;
        this.openedMenu = [];
        this.courseEditionList = [];
        this.closeInitiativeEnabled = false;
        // Recupero il formato per la data
        this.dateFormat = this.translate.instant('generic.DATE_FORMAT');
        for (let i = 0; i < this.lastInitiatives.length; i++) {
            this.openedMenu.push(false);
        }
    }

    // Evento che cancella l'iniziativa
    onDelete(initiative: Item): void {
        this.openRemoveInitiativeConfirmModal.emit(initiative);
    }

    // Riguardo al click del mouse fuori dal menu azioni
    @HostListener('mousedown', ['$event']) onClick(e) {
        if (this.menu._results.filter((x: any) => x.nativeElement.style.display == "block").length && e.target.nodeName != "LI" && e.target.className != "item") {
            let openedMenus = this.menu._results.filter((x: any) => x.nativeElement.style.display == "block");
            for (let i = 0; i < openedMenus.length; i++) {
                openedMenus[i].nativeElement.style.display = "none";
            }
        }
    }

    // Apre il menu della tabella
    toggleMenu(index: number, initiative: Item) {
        if (this.menu._results[index].nativeElement.style.display == "none") {
            this.menu._results[index].nativeElement.style.display = "block";
        } else {
            this.menu._results[index].nativeElement.style.display = "none";
        }
        this.initiative = initiative;
        this.controlIfArchivedIsVisible();

    }

    // Evento che alla pagina di modifica dell'iniziativa
    onEdit(initiative: Item): void {
        this.goToEdit.emit(initiative);
    }

    controlIfArchivedIsVisible() {
        this.formatCourseEditions(this.initiative.itemChilds.map(x => x.childObject)
            .filter(x => x && x.itemType === ItemTypes.COURSE_MODULE));
    }

    formatCourseEditions(courseModules: Array<Item>) {
        let editionList = [];
        this.courseEditionList.length = 0;
        this.currentStatus = ItemUtil.getAttributeByKey(this.initiative, ItemAttributeTypes.STATUS) || <ItemAttribute>{};
        if (courseModules && courseModules.length) {
            courseModules.forEach(courseModule => {
                // Carico le edizioni (qualora ce ne fossero)
                editionList = editionList.concat(
                    CourseModuleUtil.listCourseEditionsFromModule(courseModule, this.initiative, false, this.langsService, null, null, true)
                );
            });

            this.courseEditionList = editionList;


            // controllo se tutte le edizioni dell'iniziativa sono nel passato per poter abilitare il tasto di chiusura dell'iniziativa
            const existsPendingDate = !!this.courseEditionList.find((edition) => {
                if (!edition.lastDayDate) {
                    // provo a controllare l'ultima giornata dell'edizione
                    if (edition.courseSchedules && edition.courseSchedules.length) {
                        const schedule = edition.courseSchedules[edition.courseSchedules.length - 1];
                        return !!schedule.timeSchedules.find(timeSchedule => moment().utc().isBefore(timeSchedule.endTime));
                    }
                } else {
                    return !!moment().utc().isBefore(edition.lastDayDate);
                }
            });

            this.closeInitiativeEnabled = this.courseEditionList.length && !existsPendingDate && this.currentStatus.attributeValue !== ItemAttributeStatusTypes.CLOSED;
        }
    }

    openCloseInitiativeModal() {
        let numOfAbsent = 0;
        // Recupero i conteggi di ogni editione divisi per stato
        this.initiativeService.listTakerStatusesWithCount(null, this.courseEditionList.map(edition => edition.itemId))
            .subscribe(
                (senecaResponse: SenecaResponse<KeyValue<number>[]>) => {
                    // Formatto i dati
                    if (senecaResponse && senecaResponse.response) {

                        let takerStatuses = senecaResponse.response;
                        takerStatuses.forEach(statusCounter => {
                            // contiamo i confermati e gli invitati che verrano segnati come assenti
                            if (statusCounter.key === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED
                                || statusCounter.key === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED) {
                                numOfAbsent += statusCounter.value
                            }
                        });

                        let modalMessage = this.translate.instant('modals.CLOSE_INITIATIVE_TEXT_1');
                        if (numOfAbsent > 0) {
                            modalMessage += this.translate.instant(numOfAbsent == 1 ? 'modals.CLOSE_INITIATIVE_TEXT_2_S' : 'modals.CLOSE_INITIATIVE_TEXT_2').replace('<NUM_OF_ABSENT>', numOfAbsent);
                        }

                        // Prima di aprire la modale, setto le traduzioni e i metodi da agganciare le funzioni
                        const confimation = new EventEmitter<any>();
                        const confirmModalData: Object = {
                            modalTitle: this.translate.instant('modals.CLOSE_INITIATIVE_TITLE'),
                            modalBody: modalMessage,
                            cancelBtnLabel: "Annulla",
                            confirmBtnLabel: "Conferma",
                            confirmation: confimation,
                            dismissAction: (componentRef) => {
                            }
                        };
                        const confirmModalRef = this.ngxSmartModalService.getModal('confirmModal');
                        confirmModalRef.setData(confirmModalData, true);
                        // Sto in ascolto del dismiss, cioè dell'annullamento
                        confirmModalRef.onDismiss
                            .pipe(take(1))
                            .subscribe(() => {
                                // Pulisco il template
                            });
                        confimation
                            .pipe(take(1))
                            .subscribe((cancelMessage: string) => {
                                confirmModalRef.onCloseFinished
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.initiativeService.closeInitiative(this.initiative.itemId)
                                            .subscribe(data => {
                                                if (data.error) {
                                                    // Mostro il toaster di errore
                                                    this.toastr.error(data.error);
                                                } else {
                                                    this.toastr.success(this.translate.instant('initiatives.INITIATIVE_ARCHIVED'));
                                                    this.getLastInitiatives.emit(null);
                                                }
                                            },
                                                (err) => {
                                                    this.toastr.error(err.message);
                                                })
                                    });
                            });
                        // Apro la modale
                        confirmModalRef.open();
                    }
                });
    }

    onClone(initiative: Item): void {
        this.openCloneInitiativeConfirmModal.emit(initiative);
    }
}