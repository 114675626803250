/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '../translate/translate.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TemplateListPlaceholderComponent } from '../core/placeholders/template-list/template-list-placeholder.component';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TabSetComponent } from '../core/tabset/tabset.component';
import { TabComponent } from '../core/tab/tab.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { MomentModule } from 'ngx-moment';
import { SidebarModule } from 'ng-sidebar';
import { EditInitiativePlaceholderComponent } from '../core/placeholders/edit-initiative/edit-initiative-placeholder.component';
import { GeneralInfoPlaceholderComponent } from '../core/placeholders/general-info/general-info-placeholder.component';
import { SyllabusPlaceholderComponent } from '../core/placeholders/syllabus/syllabus-placeholder.component';
import { ChooseCreatedProposalModalComponent } from '../core/modals/choose-created-proposal-modal/choose-created-proposal-modal.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ConfirmModalComponent } from '../core/modals/confirm-modal/confirm-modal.component';
import { TagSelectComponent } from '../core/tag-select/tag-select.component';
import { TextInputComponent } from '../core/text-input/text-input.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SimpleCardComponent } from '../core/simple-card/simple-card.component';
import { OptionCardComponent } from '../core/option-card/option-card.component';
import { ChooseNewSupplierModalComponent } from '../core/modals/choose-new-supplier-modal/choose-new-supplier-modal.component';
import { BaseComponent } from './components/base-component.component';
import { BaseSubscriberComponent } from './components/base-subscriber.component';
import { BaseFormComponent } from './components/base-form.component';
import { SyllabusBasicEditorComponent } from '../core/syllabus-basic-editor/syllabus-basic-editor.component';
import { DatepickerComponent } from '../core/datepicker/datepicker.component';
import { AutocompleteSelectComponent } from '../core/autocomplete-select/autocomplete-select.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BasicSelectComponent } from '../core/basic-select/basic-select.component';
import { TimepickerComponent } from '../core/timepicker/timepicker.component';
import { PendingInitiativesPlaceholderComponent } from '../core/placeholders/pending-initiatives/pending-initiatives-placeholder.component';
import { NewSmartCardComponent } from '../core/cards/new-smart-card/new-smart-card.component';
import { NewConceptCardComponent } from '../core/cards/new-concept-card/new-concept-card.component';
import { NewSyllabusCardComponent } from '../core/cards/new-syllabus-card/new-syllabus-card.component';
import { CreateInitiativeCardPlaceholderComponent } from '../core/placeholders/create-initiative-card/create-initiative-card-placeholder.component';
import { ChooseStageForPathModalComponent } from '../core/modals/choose-stage-for-path-modal/choose-stage-for-path-modal.component';
import { StagesPlaceholderComponent } from '../core/placeholders/stages/stages-placeholder.component';
import { ProposasTypePipe } from './pipes/proposals-type.pipe';
import { DisableFormControlDirective } from './directive/disable-form-control.directive';
import { InitiativeListPlaceholderComponent } from '../core/placeholders/initiative-list/initiative-list-placeholder.component';
import { PdfInSidebarPlaceholderComponent } from '../core/placeholders/pdf-in-sidebar/pdf-in-sidebar-placeholder.component';
import { CardDatesAndPlacesComponent } from '../initiatives/initiative-edit/steppers/dates-and-places/card-dates-and-places.component';
import { FormCourseDayComponent } from '../initiatives/initiative-edit/steppers/dates-and-places/form-course-day.component';
import { FormCourseEditionComponent } from '../initiatives/initiative-edit/steppers/dates-and-places/form-course-edition.component';
import { SetWeightModalComponent } from '../core/modals/set-weight-modal/set-weight-modal.component';
import { ConfirmCourseSubscriptionModalComponent } from '../core/modals/confirm-course-subscription-modal/confirm-course-subscription-modal.component';
import { CancelCourseSubscriptionModalComponent } from '../core/modals/cancel-course-subscription-modal/cancel-course-subscription-modal.component';
import { ConfirmCourseSubscriptionModalEuropeAssistanceComponent } from '../core/modals/confirm-course-subscription-modal-europe-assistance/confirm-course-subscription-modal-europe-assistance.component';
import { AgmCoreModule } from '@agm/core';
import { FileUploadModule } from 'ng2-file-upload';
import { DropdownMenuComponent } from '../core/dropdown-menu/dropdown-menu.component';
import { DropdownMenuItemDirective } from '../core/dropdown-menu/dropdown-menu-item.directive';
import { KalturaPlayerComponent } from '../core/kaltura-player/kaltura-player.component';
import { KalturaPlayerModalComponent } from '../core/modals/kaltura-player-modal/kaltura-player-modal.component';
import { NumberAbbreviationPipe } from './pipes/number-abbreviation.pipe';
import { RoleIndicatorPlaceholderComponent } from '../core/placeholders/role-indicator/role-indicator-placeholder.component';
import { MsgBannerComponent } from '../core/msg-banner/msg-banner.component';
import { AddUserToGroupModalComponent } from '../core/modals/add-user-to-group-modal/add-user-to-group-modal.component';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { InitiativesTableComponent } from '../core/tables/initiatives/initiatives-table.component';
import { SelectedSupplierCardComponent } from '../core/cards/selected-supplier-card/selected-supplier-card.component';
import { ImportUsersFromEditionModalComponent } from '../core/modals/import-users-from-edition-modal/import-users-from-edition-modal.component';
import { CardSyllabusComponent } from '../initiatives/initiative-edit/steppers/from-syllabus/card-syllabus/card-syllabus.component';
import { LandingPerformanceManagerCardComponent } from '../core/landing-performance-manager-card/landing-performance-manager-card.component';
import { PerformanceManagerEditCard } from '../core/performance-manager-edit-card/performance-manager-edit-card';

import { MonitoringPerformanceModalComponent } from '../core/modals/monitoring-performance-modal-sollicits/monitoring-performance-modal-solicits.component';
import { SendMailPersonsReportPerformanceModalComponent } from '../core/modals/send-mail-persons-report-performance-modal/send-mail-persons-report-performance-modal.component';
import { PublicUsersModalComponent } from '../core/modals/public-users-privacy-modal/public-users-privacy-modal.component';
import { DisableWheelDirective } from './directive/disable-wheel.directive';
import { SendCalendarEditionModalComponent } from '../core/modals/send-calendar-edition/send-calendar-edition-modal.component';
import { InsertHourValueModalComponent } from '../core/modals/insert-hour-value/insert-hour-value-modal-modal.component';
import { SwitchBrowserModalComponent } from '../core/modals/switch-browser-modal/switch-browser-modal.component';
import { ConfirmCloneInitiativeModalComponent } from '../core/modals/confirm-clone-initiative-modal/confirm-clone-initiative-modal.component';

@NgModule({
    declarations: [
        MonitoringPerformanceModalComponent,
        SafeHtmlPipe,
        ProposasTypePipe,
        NumberAbbreviationPipe,
        OrderByDatePipe,
        /* Componenti condivisi dai vari moduli */
        TabSetComponent,
        TabComponent,
        TemplateListPlaceholderComponent,
        EditInitiativePlaceholderComponent,
        GeneralInfoPlaceholderComponent,
        SyllabusPlaceholderComponent,
        RoleIndicatorPlaceholderComponent,
        InitiativeListPlaceholderComponent,
        PdfInSidebarPlaceholderComponent,
        ChooseNewSupplierModalComponent,
        ChooseStageForPathModalComponent,
        AddUserToGroupModalComponent,
        ChooseCreatedProposalModalComponent,
        PaginationComponent,
        TagSelectComponent,
        InitiativesTableComponent,
        SelectedSupplierCardComponent,
        CardSyllabusComponent,
        ImportUsersFromEditionModalComponent,
        TextInputComponent,
        SimpleCardComponent,
        LandingPerformanceManagerCardComponent,
        OptionCardComponent,
        BaseComponent,
        BaseSubscriberComponent,
        BaseFormComponent,
        ConfirmModalComponent,
        PublicUsersModalComponent,
        SwitchBrowserModalComponent,
        InsertHourValueModalComponent,
        SendCalendarEditionModalComponent,
        SetWeightModalComponent,
        ConfirmCourseSubscriptionModalComponent,
        CancelCourseSubscriptionModalComponent,
        ConfirmCourseSubscriptionModalEuropeAssistanceComponent,
        SyllabusBasicEditorComponent,
        DatepickerComponent,
        AutocompleteSelectComponent,
        TimepickerComponent,
        FormCourseEditionComponent,
        FormCourseDayComponent,
        CardDatesAndPlacesComponent,
        CreateInitiativeCardPlaceholderComponent,
        NewSyllabusCardComponent,
        NewConceptCardComponent,
        StagesPlaceholderComponent,
        NewSmartCardComponent,
        BasicSelectComponent,
        PendingInitiativesPlaceholderComponent,
        DisableFormControlDirective,
        DisableWheelDirective,
        DropdownMenuComponent,
        DropdownMenuItemDirective,
        KalturaPlayerComponent,
        KalturaPlayerModalComponent,
        MsgBannerComponent,
        PerformanceManagerEditCard,
        SendMailPersonsReportPerformanceModalComponent,
        ConfirmCloneInitiativeModalComponent
        /* fine componenti condivisi */
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        TooltipModule,
        NgSelectModule,
        AppTranslateModule.forRoot(),
        FileUploadModule,
        CKEditorModule,
        MomentModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBZ25MNayUTrWKe9uvvPj3DyoihFuGpCvY'
        }),
        NgxSmartModalModule.forRoot(),
        SidebarModule,
        AngularSvgIconModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ],
    exports: [
        MonitoringPerformanceModalComponent,
        CommonModule,
        FlexLayoutModule,
        AppTranslateModule,
        TooltipModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        CKEditorModule,
        /* export componenti comuni */
        TabSetComponent,
        TabComponent,
        PaginationComponent,
        ChooseCreatedProposalModalComponent,
        ChooseNewSupplierModalComponent,
        ChooseStageForPathModalComponent,
        AddUserToGroupModalComponent,
        TemplateListPlaceholderComponent,
        EditInitiativePlaceholderComponent,
        GeneralInfoPlaceholderComponent,
        SyllabusPlaceholderComponent,
        RoleIndicatorPlaceholderComponent,
        SafeHtmlPipe,
        ProposasTypePipe,
        NumberAbbreviationPipe,
        OrderByDatePipe,
        MomentModule,
        AgmCoreModule,
        InitiativeListPlaceholderComponent,
        FormCourseEditionComponent,
        FormCourseDayComponent,
        CardDatesAndPlacesComponent,
        PdfInSidebarPlaceholderComponent,
        NgSelectModule,
        AngularSvgIconModule,
        NgxSmartModalModule,
        SidebarModule,
        TagSelectComponent,
        InitiativesTableComponent,
        SelectedSupplierCardComponent,
        CardSyllabusComponent,
        ImportUsersFromEditionModalComponent,
        TextInputComponent,
        SimpleCardComponent,
        LandingPerformanceManagerCardComponent,
        OptionCardComponent,
        BaseComponent,
        ConfirmModalComponent,
        PublicUsersModalComponent,
        SwitchBrowserModalComponent,
        InsertHourValueModalComponent,
        SendCalendarEditionModalComponent,
        SetWeightModalComponent,
        ConfirmCourseSubscriptionModalComponent,
        CancelCourseSubscriptionModalComponent,
        ConfirmCourseSubscriptionModalEuropeAssistanceComponent,
        CreateInitiativeCardPlaceholderComponent,
        StagesPlaceholderComponent,
        NewSyllabusCardComponent,
        NewConceptCardComponent,
        NewSmartCardComponent,
        BaseSubscriberComponent,
        BaseFormComponent,
        SyllabusBasicEditorComponent,
        DatepickerComponent,
        AutocompleteSelectComponent,
        TimepickerComponent,
        BasicSelectComponent,
        PendingInitiativesPlaceholderComponent,
        DisableWheelDirective,
        DisableFormControlDirective,
        DropdownMenuComponent,
        DropdownMenuItemDirective,
        KalturaPlayerComponent,
        KalturaPlayerModalComponent,
        MsgBannerComponent,
        PerformanceManagerEditCard,
        SendMailPersonsReportPerformanceModalComponent,
        ConfirmCloneInitiativeModalComponent
        /* export componenti comuni */
    ]
})

export class SharedModule {
}
